import React, { useState } from 'react';
import {
  // FaFacebook,
  // FaYoutube,
  // FaTwitter,
  // FaInstagram,
  // FaLinkedinIn,
  FaBars,
  FaAngleDown,
  FaTimes,
  FaAngleUp,
} from 'react-icons/fa';
import { Menu, Transition } from '@headlessui/react';
import { useNavigate, useLocation } from 'react-router-dom';
const NewNavBar = () => {
  const [toggleNav, setToggleNav] = useState(false);
  const [toggleAi, setToggleAi] = useState(false);
  const [toggleServices, setToggleServices] = useState(false);
  const [toggleIndustries, setToggleIndustries] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split('/');
  return (
      <header>
        <div className="md:pb-24">
          <nav className="items-center flex border-solid px-2 sm:px-12 shadow-lg md:fixed md:inset-x-0 py-4 md:py-0 md:h-24 md:top-0 bg-white z-50">
            <div className="flex-1">
              <div className="flex flex-row justify-evenly">
                <div
                    className="cursor-pointer flex-1 justify-center items-center pt-1"
                    onClick={() => navigate('/')}
                >
                  <img
                      src={require('../../assets/images/logo.png')}
                      className="sm:pr-2"
                      alt="company logo"
                  />
                </div>

                {/* Mobile Toggle bar */}
                <div className="flex sm:hidden flex-1 justify-end items-center px-4 ">
                  <div>
                    {!toggleNav && (
                        <FaBars
                            className="text-2xl m-2 text-sct-primary cursor-pointer"
                            onClick={() => {
                              setToggleNav(true);
                            }}
                        />
                    )}
                    {toggleNav && (
                        <FaTimes
                            className="text-2xl m-2 text-sct-primary cursor-pointer"
                            onClick={() => {
                              setToggleNav(false);
                            }}
                        />
                    )}
                  </div>
                </div>

                <ul className="hidden sm:flex flex-2 sm:gap-3 items-center md:gap-5 my-3 justify-end">
                  <li
                      className={`cursor-pointer hover:text-sct-primary sm:text-sm lg:text-base ${
                          splitLocation[1] === '' ? 'nav-active' : ''
                      }`}
                      onClick={() => navigate('/')}
                  >
                    Home
                  </li>
                  <Menu as="li" className="flex">
                    {({ open }) => (
                        <>
                          <Menu.Button
                              className={`cursor-pointer hover:text-sct-primary flex flex-row items-center sm:text-sm lg:text-base ${
                                  splitLocation[1] === 'ai-software' ? 'nav-active' : ''
                              }`}
                          >
                            Products
                            <FaAngleDown className="text-lg pl-2" />
                          </Menu.Button>
                          <Transition show={open}>
                            <Menu.Items className="absolute inset-x-0 py-4 px-16 bg-white mt-14 grid grid-cols-4 border-t-2 shadow-lg">
                              <Menu.Item>
                                <ul className="inline-block">
                                  <li
                                      className={`cursor-pointer my-4 hover:text-sct-primary ${
                                          splitLocation[2] === 'datrix' &&
                                          splitLocation[1] === 'ai-software'
                                              ? 'nav-active'
                                              : ''
                                      }`}
                                      onClick={() => navigate('products/shopzilla')}
                                  >
                                    Shopzilla
                                  </li>
                                  <li
                                      className={`cursor-pointer my-4 hover:text-sct-primary ${
                                          splitLocation[2] === 'i-scrumai'
                                              ? 'nav-active'
                                              : ''
                                      }`}
                                      onClick={() =>
                                          navigate('products/i-scrumai')
                                      }
                                  >
                                    iScrum AI
                                  </li>
                                </ul>
                              </Menu.Item>
                              <Menu.Item>
                                <ul className="inline-block">
                                  <li
                                      className={`cursor-pointer my-4 hover:text-sct-primary ${
                                          splitLocation[2] === 'datrix' &&
                                          splitLocation[1] === 'products'
                                              ? 'nav-active'
                                              : ''
                                      }`}
                                      onClick={() => navigate('products/datrix')}
                                  >
                                    DatriX AI
                                  </li>
                                  <li
                                      className={`cursor-pointer my-4 hover:text-sct-primary ${
                                          splitLocation[2] === 'datrix' &&
                                          splitLocation[1] === 'ai-software'
                                              ? 'nav-active'
                                              : ''
                                      }`}
                                      onClick={() => navigate('products/investify')}
                                  >
                                    Investify
                                  </li>
                                </ul>
                              </Menu.Item>
                              {/* <Menu.Item>
                            <ul className="inline-block">
                              <li
                                  className={`cursor-pointer my-4 hover:text-sct-primary ${
                                      splitLocation[2] === 'shopzilla' &&
                                      splitLocation[1] === 'products'
                                          ? 'nav-active'
                                          : ''
                                  }`}
                                  onClick={() => navigate('products/shopzilla')}
                              >
                                Shopzilla
                              </li>
                            </ul>
                          </Menu.Item>*/}
                            </Menu.Items>
                          </Transition>
                        </>
                    )}
                  </Menu>

                  <Menu as="li" className="flex">
                    {({ open }) => (
                        <>
                          <Menu.Button
                              className={`cursor-pointer hover:text-sct-primary flex flex-row items-center sm:text-sm lg:text-base ${
                                  splitLocation[1] === 'services' ? 'nav-active' : ''
                              }`}
                          >
                            Services
                            <FaAngleDown className="text-lg pl-2" />
                          </Menu.Button>
                          <Transition show={open}>
                            <Menu.Items className="absolute inset-x-0 py-4 px-16 bg-white mt-14 grid grid-cols-4 border-t-2 shadow-lg">
                              <Menu.Item>
                                <ul className="inline-block">
                                  <li
                                      className={`cursor-pointer my-4 hover:text-sct-primary ${
                                          splitLocation[2] === 'application-services'
                                              ? 'nav-active'
                                              : ''
                                      }`}
                                      onClick={() =>
                                          navigate('services/application-services')
                                      }
                                  >
                                    Application Services
                                  </li>
                                  <li
                                      className={`cursor-pointer my-4 hover:text-sct-primary ${
                                          splitLocation[2] === 'data-analytics'
                                              ? 'nav-active'
                                              : ''
                                      }`}
                                      onClick={() =>
                                          navigate('services/data-analytics')
                                      }
                                  >
                                    Data Analytics
                                  </li>
                                  <li
                                      className={`cursor-pointer my-4 hover:text-sct-primary ${
                                          splitLocation[2] === 'technology-consulting'
                                              ? 'nav-active'
                                              : ''
                                      }`}
                                      onClick={() =>
                                          navigate('services/technology-consulting')
                                      }
                                  >
                                    Technology Consulting
                                  </li>
                                  <li
                                      className={`cursor-pointer my-4 hover:text-sct-primary ${
                                          splitLocation[2] === 'datrix' &&
                                          splitLocation[1] === 'services'
                                              ? 'nav-active'
                                              : ''
                                      }`}
                                      onClick={() => navigate('services/datrix')}
                                  >
                                    Artificial Inteligence
                                  </li>
                                </ul>
                              </Menu.Item>
                              <Menu.Item>
                                <ul className="inline-block">
                                  <li
                                      className={`cursor-pointer my-4 hover:text-sct-primary ${
                                          splitLocation[2] === 'technology-innovation'
                                              ? 'nav-active'
                                              : ''
                                      }`}
                                      onClick={() =>
                                          navigate('services/technology-innovation')
                                      }
                                  >
                                    Technology Innovation
                                  </li>
                                  <li
                                      className={`cursor-pointer my-4 hover:text-sct-primary ${
                                          splitLocation[2] === 'cloud-consulting'
                                              ? 'nav-active'
                                              : ''
                                      }`}
                                      onClick={() =>
                                          navigate('services/cloud-consulting')
                                      }
                                  >
                                    Cloud Consulting
                                  </li>
                                  <li
                                      className={`cursor-pointer my-4 hover:text-sct-primary ${
                                          splitLocation[2] === 'testing'
                                              ? 'nav-active'
                                              : ''
                                      }`}
                                      onClick={() => navigate('services/testing')}
                                  >
                                    Test Automation
                                  </li>
                                </ul>
                              </Menu.Item>
                            </Menu.Items>
                          </Transition>
                        </>
                    )}
                  </Menu>

                  {/*<Menu as="li" className="flex"> TODO: disable temporarily - Needs Content for industries.
                  {({ open }) => (
                    <>
                      <Menu.Button
                        className={`cursor-pointer hover:text-sct-primary flex flex-row items-center sm:text-sm lg:text-base ${
                          splitLocation[1] === 'industries' ? 'nav-active' : ''
                        }`}
                      >
                        Industries
                        <FaAngleDown className="text-lg pl-2" />
                      </Menu.Button>
                      <Transition show={open}>
                        <Menu.Items className="absolute inset-x-0 py-4 px-16 bg-white mt-14 grid grid-cols-4 border-t-2 shadow-lg">
                          <Menu.Item>
                            <ul className="inline-block">
                              <li
                                className={`cursor-pointer my-4 hover:text-sct-primary ${
                                  splitLocation[2] === 'automotive'
                                    ? 'nav-active'
                                    : ''
                                }`}
                                onClick={() =>
                                  navigate('industries/automotive')
                                }
                              >
                                Automotive
                              </li>
                              <li
                                className={`cursor-pointer my-4 hover:text-sct-primary ${
                                  splitLocation[2] === 'banking'
                                    ? 'nav-active'
                                    : ''
                                }`}
                                onClick={() => navigate('industries/banking')}
                              >
                                Banking
                              </li>
                              <li
                                className={`cursor-pointer my-4 hover:text-sct-primary ${
                                  splitLocation[2] === 'communications'
                                    ? 'nav-active'
                                    : ''
                                }`}
                                onClick={() =>
                                  navigate('industries/communications')
                                }
                              >
                                Communications and Media
                              </li>
                              <li
                                className={`cursor-pointer my-4 hover:text-sct-primary ${
                                  splitLocation[2] === 'capital-markets'
                                    ? 'nav-active'
                                    : ''
                                }`}
                                onClick={() =>
                                  navigate('industries/capital-markets')
                                }
                              >
                                Capital Markets
                              </li>
                            </ul>
                          </Menu.Item>
                          <Menu.Item>
                            <ul className="inline-block">
                              <li
                                className={`cursor-pointer my-4 hover:text-sct-primary ${
                                  splitLocation[2] === 'consumer-goods'
                                    ? 'nav-active'
                                    : ''
                                }`}
                                onClick={() =>
                                  navigate('industries/consumer-goods')
                                }
                              >
                                Consumer Goods and Services
                              </li>
                              <li
                                className={`cursor-pointer my-4 hover:text-sct-primary ${
                                  splitLocation[2] === 'insurance'
                                    ? 'nav-active'
                                    : ''
                                }`}
                                onClick={() => navigate('industries/insurance')}
                              >
                                Insurance
                              </li>
                              <li
                                className={`cursor-pointer my-4 hover:text-sct-primary ${
                                  splitLocation[2] === 'retail'
                                    ? 'nav-active'
                                    : ''
                                }`}
                                onClick={() => navigate('industries/retail')}
                              >
                                Retail
                              </li>
                            </ul>
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </>
                  )}
                </Menu>*/}

                  <li
                      className={`cursor-pointer hover:text-sct-primary sm:text-sm lg:text-base ${
                          splitLocation[1] === 'testimonials' ? 'nav-active' : ''
                      }`}
                      onClick={() => navigate('testimonials')}
                  >
                    Testimonials
                  </li>

                  <li
                      className={`cursor-pointer hover:text-sct-primary sm:text-sm lg:text-base ${
                          splitLocation[1] === 'careers' ? 'nav-active' : ''
                      }`}
                      onClick={() => navigate('careers')}
                  >
                    Careers
                  </li>

                  <li
                      className={`cursor-pointer hover:text-sct-primary sm:text-sm lg:text-base ${
                          splitLocation[1] === 'contactus' ? 'nav-active' : ''
                      }`}
                      onClick={() => navigate('contactus')}
                  >
                    Contact Us
                  </li>
                </ul>
              </div>

              {/* Mobile Menu */}
              {toggleNav && (
                  <div className="sm:hidden mt-6 inset-x-0 absolute z-50 bg-white top-11">
                    <ul className="block text-center my-1">
                      <li
                          className={`cursor-pointer text-base hover:bg-sct-primary hover:text-white py-2 ${
                              splitLocation[1] === '' ? 'nav-sm-active' : ''
                          }`}
                          onClick={() => {
                            setToggleNav(false);
                            navigate('/');
                          }}
                      >
                        Home
                      </li>
                      <li
                          className={`cursor-pointer text-base hover:bg-sct-primary hover:text-white py-2 mt-1 flex flex-row items-center justify-center ${
                              splitLocation[1] === 'ai-software' ? 'nav-sm-active' : ''
                          }`}
                          onClick={() => {
                            setToggleAi(!toggleAi);
                          }}
                      >
                        Products{' '}
                        {!toggleAi ? (
                            <FaAngleDown className="text-lg pl-2" />
                        ) : (
                            <FaAngleUp className="text-lg pl-2" />
                        )}
                      </li>
                      {toggleAi && (
                          <div className="border-2 border-sct-primary">
                            <li
                                className={`${
                                    splitLocation[2] === 'shopzilla'
                                        ? 'nav-sm-active'
                                        : ''
                                } cursor-pointer text-base hover:bg-sct-primary mt-1 hover:text-white py-2`}
                                onClick={() => {
                                  setToggleAi(false);
                                  setToggleNav(false);
                                  navigate('products/shopzilla');
                                }}
                            >
                              Shopzilla
                            </li>
                            <li
                                className={`${
                                    splitLocation[2] === 'i-scrumai'
                                        ? 'nav-sm-active'
                                        : ''
                                } cursor-pointer text-base hover:bg-sct-primary mt-1 hover:text-white py-2`}
                                onClick={() => {
                                  setToggleAi(false);
                                  setToggleNav(false);
                                  navigate('products/i-scrumai');
                                }}
                            >
                              iScrum AI
                            </li>
                            <li
                                className={`${
                                    splitLocation[2] === 'datrix' ? 'nav-sm-active' : ''
                                } cursor-pointer text-base hover:bg-sct-primary mt-1 hover:text-white py-2`}
                                onClick={() => {
                                  setToggleAi(false);
                                  setToggleNav(false);
                                  navigate('products/datrix');
                                }}
                            >
                              DatriX AI
                            </li>
                            <li
                                className={`${
                                    splitLocation[2] === 'investify'
                                        ? 'nav-sm-active'
                                        : ''
                                } cursor-pointer text-base hover:bg-sct-primary mt-1 hover:text-white py-2`}
                                onClick={() => {
                                  setToggleAi(false);
                                  setToggleNav(false);
                                  navigate('products/investify');
                                }}
                            >
                              Investify
                            </li>
                          </div>
                      )}
                      <li
                          className={`cursor-pointer text-base hover:bg-sct-primary hover:text-white py-2 mt-1 flex flex-row items-center justify-center ${
                              splitLocation[1] === 'services' ? 'nav-sm-active' : ''
                          }`}
                          onClick={() => {
                            setToggleServices(!toggleServices);
                          }}
                      >
                        Services{' '}
                        {!toggleServices ? (
                            <FaAngleDown className="text-lg pl-2" />
                        ) : (
                            <FaAngleUp className="text-lg pl-2" />
                        )}
                      </li>
                      {toggleServices && (
                          <div className="border-2 border-sct-primary">
                            <li
                                className={`${
                                    splitLocation[2] === 'application-services'
                                        ? 'nav-sm-active'
                                        : ''
                                } cursor-pointer text-base hover:bg-sct-primary mt-1 hover:text-white py-2`}
                                onClick={() => {
                                  setToggleServices(false);
                                  setToggleNav(false);
                                  navigate('services/application-services');
                                }}
                            >
                              Application Services
                            </li>
                            <li
                                className={`${
                                    splitLocation[2] === 'data-analytics'
                                        ? 'nav-sm-active'
                                        : ''
                                } cursor-pointer text-base hover:bg-sct-primary mt-1 hover:text-white py-2`}
                                onClick={() => {
                                  setToggleServices(false);
                                  setToggleNav(false);
                                  navigate('services/data-analytics');
                                }}
                            >
                              Data Analytics
                            </li>
                            <li
                                className={`${
                                    splitLocation[2] === 'technology-consulting'
                                        ? 'nav-sm-active'
                                        : ''
                                } cursor-pointer text-base hover:bg-sct-primary mt-1 hover:text-white py-2`}
                                onClick={() => {
                                  setToggleServices(false);
                                  setToggleNav(false);
                                  navigate('services/technology-consulting');
                                }}
                            >
                              Technology Consulting
                            </li>
                            <li
                                className={`${
                                    splitLocation[2] === 'datrix' &&
                                    splitLocation[1] === 'services'
                                        ? 'nav-sm-active'
                                        : ''
                                } cursor-pointer text-base hover:bg-sct-primary mt-1 hover:text-white py-2`}
                                onClick={() => {
                                  setToggleServices(false);
                                  setToggleNav(false);
                                  navigate('services/datrix');
                                }}
                            >
                              Artificial Inteligence
                            </li>
                            <li
                                className={`${
                                    splitLocation[2] === 'technology-innovation'
                                        ? 'nav-sm-active'
                                        : ''
                                } cursor-pointer text-base hover:bg-sct-primary mt-1 hover:text-white py-2`}
                                onClick={() => {
                                  setToggleServices(false);
                                  setToggleNav(false);
                                  navigate('services/technology-innovation');
                                }}
                            >
                              Technology Innovation
                            </li>
                            <li
                                className={`${
                                    splitLocation[2] === 'cloud-consulting'
                                        ? 'nav-sm-active'
                                        : ''
                                } cursor-pointer text-base hover:bg-sct-primary mt-1 hover:text-white py-2`}
                                onClick={() => {
                                  setToggleServices(false);
                                  setToggleNav(false);
                                  navigate('services/cloud-consulting');
                                }}
                            >
                              Cloud Consulting
                            </li>
                            <li
                                className={`${
                                    splitLocation[2] === 'testing'
                                        ? 'nav-sm-active'
                                        : ''
                                } cursor-pointer text-base hover:bg-sct-primary mt-1 hover:text-white py-2`}
                                onClick={() => {
                                  setToggleServices(false);
                                  setToggleNav(false);
                                  navigate('services/testing');
                                }}
                            >
                              Test Automation
                            </li>
                          </div>
                      )}

                      {/*<li
                          className={`cursor-pointer text-base hover:bg-sct-primary hover:text-white py-2 mt-1 flex flex-row items-center justify-center ${
                              splitLocation[1] === 'industries' ? 'nav-sm-active' : ''
                          }`}
                          onClick={() => {
                            setToggleIndustries(!toggleIndustries);
                          }}
                      >
                        Industries{' '}
                        {!toggleIndustries ? (
                            <FaAngleDown className="text-lg pl-2" />
                        ) : (
                            <FaAngleUp className="text-lg pl-2" />
                        )}
                      </li>
                      {toggleIndustries && (
                          <div className="border-2 border-sct-primary">
                            <li
                                className={`${
                                    splitLocation[2] === 'automotive'
                                        ? 'nav-sm-active'
                                        : ''
                                } cursor-pointer text-base hover:bg-sct-primary mt-1 hover:text-white py-2`}
                                onClick={() => {
                                  setToggleIndustries(false);
                                  setToggleNav(false);
                                  navigate('industries/automotive');
                                }}
                            >
                              Automotive
                            </li>
                            <li
                                className={`${
                                    splitLocation[2] === 'banking' ? 'nav-sm-active' : ''
                                } cursor-pointer text-base hover:bg-sct-primary mt-1 hover:text-white py-2`}
                                onClick={() => {
                                  setToggleIndustries(false);
                                  setToggleNav(false);
                                  navigate('industries/banking');
                                }}
                            >
                              Banking
                            </li>
                            <li
                                className={`${
                                    splitLocation[2] === 'communications'
                                        ? 'nav-sm-active'
                                        : ''
                                } cursor-pointer text-base hover:bg-sct-primary mt-1 hover:text-white py-2`}
                                onClick={() => {
                                  setToggleIndustries(false);
                                  setToggleNav(false);
                                  navigate('industries/communications');
                                }}
                            >
                              Communications and Media
                            </li>
                            <li
                                className={`${
                                    splitLocation[2] === 'capital-markets'
                                        ? 'nav-sm-active'
                                        : ''
                                } cursor-pointer text-base hover:bg-sct-primary mt-1 hover:text-white py-2`}
                                onClick={() => {
                                  setToggleIndustries(false);
                                  setToggleNav(false);
                                  navigate('industries/capital-markets');
                                }}
                            >
                              Capital Markets
                            </li>
                            <li
                                className={`${
                                    splitLocation[2] === 'consumer-goods'
                                        ? 'nav-sm-active'
                                        : ''
                                } cursor-pointer text-base hover:bg-sct-primary mt-1 hover:text-white py-2`}
                                onClick={() => {
                                  setToggleIndustries(false);
                                  setToggleNav(false);
                                  navigate('industries/consumer-goods');
                                }}
                            >
                              Consumer Goods and Services
                            </li>
                            <li
                                className={`${
                                    splitLocation[2] === 'insurance'
                                        ? 'nav-sm-active'
                                        : ''
                                } cursor-pointer text-base hover:bg-sct-primary mt-1 hover:text-white py-2`}
                                onClick={() => {
                                  setToggleIndustries(false);
                                  setToggleNav(false);
                                  navigate('industries/insurance');
                                }}
                            >
                              Insurance
                            </li>
                            <li
                                className={`${
                                    splitLocation[2] === 'retail' ? 'nav-sm-active' : ''
                                } cursor-pointer text-base hover:bg-sct-primary mt-1 hover:text-white py-2`}
                                onClick={() => {
                                  setToggleIndustries(false);
                                  setToggleNav(false);
                                  navigate('industries/retail');
                                }}
                            >
                              Retail
                            </li>
                          </div>
                      )}*/}

                      <li
                          className={`cursor-pointer text-base hover:bg-sct-primary hover:text-white py-2 mt-1 ${
                              splitLocation[1] === 'testimonials' ? 'nav-sm-active' : ''
                          }`}
                          onClick={() => {
                            setToggleNav(false);
                            navigate('testimonials');
                          }}
                      >
                        Testimonials
                      </li>
                      <li
                          className={`cursor-pointer text-base hover:bg-sct-primary hover:text-white py-2 mt-1 ${
                              splitLocation[1] === 'careers' ? 'nav-sm-active' : ''
                          }`}
                          onClick={() => {
                            setToggleNav(false);
                            navigate('careers');
                          }}
                      >
                        Careers
                      </li>
                      <li
                          className={`cursor-pointer text-base hover:bg-sct-primary hover:text-white py-2 mt-1 ${
                              splitLocation[1] === 'contactus' ? 'nav-sm-active' : ''
                          }`}
                          onClick={() => {
                            setToggleNav(false);
                            navigate('contactus');
                          }}
                      >
                        Contact Us
                      </li>
                    </ul>
                  </div>
              )}
            </div>
          </nav>
        </div>
      </header>
  );
};

export default NewNavBar;
