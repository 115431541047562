import React from 'react';

const ScrumHeroSection = () => {
  return (
    <section className="relative my-16">
      <div className="container flex flex-col-reverse lg:flex-row items-center gap-12">
        {/* Content */}
        <div className="flex flex-1 flex-col items-center lg:items-start">
          <h2 className="text-sct-primary text-xl md:text-2xl lg:text-3xl text-center lg:text-left mb-6 font-semibold">
            iScrum AI
          </h2>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            Our AI-powered meeting solution will manage every meeting effectively, while driving
            innovation, collaboration, and cost-efficient resource allocation at every stage of the project life
            cycle. iScrum AI allows your team to keep going full steam ahead with each sprint, while
            remaining focused on crossing the finish line with success. The result is a streamlined workflow
            that enables your organization to achieve the highest level of value for organizational
            stakeholders, partners, employees, and end consumers.
          </p>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            Let iScrum AI optimize your meeting efficiency so your team can keep their focus where it
            matters most.
          </p>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            Contact us today to schedule your initial consultation.
          </p>
        </div>
        {/* Image */}
        <div className="flex justify-center flex-1 z-10">
          <img
            className="w-5/6 h-5/6 sm:w-3/4 sm:h-3/4 md:w-full md:h-full"
            src={require('../../../assets/images/clients/iscrum_new.png')}
          />
        </div>
      </div>
    </section>
  );
};

export default ScrumHeroSection;
