import React from 'react';
import Banner from '../../components/UI/Banner';
import { useNavigate } from 'react-router-dom';

const CookieDeclaration = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Banner
        imgUrl="https://images.unsplash.com/photo-1604178737169-001c71f9d6b8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1171&q=80"
        heading="Cookie Declaration"
      />
      <div className="container my-16">
        <p className="text-sct-grey mb-2">
        SOURCE CODE TECHNOLOGIES LLC (&quot;us&quot;, &quot;we&quot;, or &quot;our&quot;) understand
        that your privacy is important to you and are committed to being
        transparent about the technologies it uses. This Cookie Policy
        (&quot;Cookie Policy&quot;) explains how and why cookies (“Cookies&quot;) may be
        stored on and accessed from your device when you use or visit any
        website or app that posts a link to this Policy (collectively, our
        &quot;Sites&quot;). Please read this Cookie Policy carefully before using our
        Site. This Cookie Policy should be read together with our Privacy
        Policy and our Terms of Use.
        By clicking &quot;Accept&quot; on the Cookie banner, you give us your consent
        to store and access Cookies and Other Tracking Technologies as
        described in this Cookie Policy.
        This Cookie Policy should be read together with our Privacy Policy
        (Privacy statement Link) and Terms of use link.
        </p>
       
        <h3 className="font-bold text-md mt-6 mb-2">What are Cookies?</h3>
        <p className="text-sct-grey mb-2">
        Cookies are small text files, often including unique identifiers, that
        are sent by web servers to web browsers, and which may then be sent
        back to the server each time the browser requests a page from the
        server.
        </p>
        <p className="text-sct-grey mb-2">
        Cookies are very useful and enable an internet site to recognize you,
        log when you visit a particular page, provide a secure connection to a
        website and enhance your user experience by: improving your
        browsing comfort, and/ or adapting the content of a page to your
        areas of interest.
        </p>
        <p className="text-sct-grey mb-2">
        To find more information, please go to <a className='cursor-pointer' href="https://www.allaboutcookies.org" target="_blank">www.allaboutcookies.org</a> if
        you are located in in the United States. If you are located in Europe,
        please go to <a className='cursor-pointer' href="https://www.youronlinechoices.eu" target="_blank">www.youronlinechoices.eu.</a>
        </p>

        <h3 className="font-bold text-md mt-6 mb-2">How does our Site use Cookies?</h3>
        <p className="text-sct-grey mb-2">
        We may use information collected from our Cookies to identify user
        behavior and to serve content and offers based on your profile, and in
        order to enhance the convenience of the users of our Site.
        </p>

        <h3 className="font-bold text-md mt-6 mb-2">Information on the Types of Cookies that are
        used on our Site</h3>
        <p className="text-sct-grey mb-2">
        We have provided details of the types of Cookies that are used on our
        Site as well as other relevant and useful information on the Cookies.
        You can also find more information on the Cookies when you click 
        the pop-up window &quot;[name of the pop-up banner]&quot;.
        </p>

        <h3 className="font-bold text-md ml-3 mt-6 mb-2">-Technical Cookies:</h3>
        <p className="text-sct-grey ml-6 mb-2">
        These Cookies are necessary to enable you to move around our Site
        and use its features. These Cookies also allow our Site to remember
        your previous action within the same browsing session.
        </p>

        <h3 className="font-bold text-md ml-3 mt-6 mb-2">-Analytical Cookies:</h3>
        <p className="text-sct-grey ml-6 mb-2">
        These Cookies are used by us or third-party service providers to
        analyze how our Sites are used and how they are performing. For
        example, we use cookies to know which country you are in when you
        request a service to help base the contents on your location. We also
        use some Cookies from Google Analytics and SiTest to obtain web
        analytics and intelligence about our Site. These tools use
        programming code to collect information about your interaction with
        our Site, such as the pages you visit, the links you click on and how
        long you are on our Site. These tools do not store any personal
        identifiable information.
        </p>

        <h3 className="font-bold text-md ml-3 mt-6 mb-2">-Functionality Cookies:</h3>
        <p className="text-sct-grey ml-6 mb-2">
        These Cookies allow our Site to remember choices you make when
        you visit our Site in order to provide enhanced, more personalized
        features to you. The types of information collected by Functionality
        Cookies are, language preference, and the region you are located in.
        </p>

        <h3 className="font-bold text-md ml-3 mt-6 mb-2">-Advertising and Retargeting Cookies:</h3>
        <p className="text-sct-grey ml-6 mb-2">
        These Cookies save information from your browsing history in order
        to record your interests and your browsing path on our Site. We may
        use advertising and targeting cookies for retargeting, which enables
        our advertising partners to target advertising to you on other
        websites, based on your visit to our Site. Without these Cookies,
        online advertisements you encounter will be less relevant to you and
        your interests.
        </p>

        <h3 className="font-bold text-md mt-6 mb-2">How do I refuse or withdraw my consent to the
        use of cookies?</h3>
        <p className="text-sct-grey mb-2">
        If you do not want Cookies to be placed on your device, we suggest
        you to set your preferences regarding Cookies. By clicking &quot;Cookie
        Preferences&quot; on the bottom of each webpage, you can select the
        types of Cookies our Site uses. You can change your preferences at
        any time; you can also withdraw the consent you have previously
        given to us.        
        </p>
        <p className="text-sct-grey mb-2">
        If you wish to remove previously-stored Cookies, you can manually
        delete the Cookies at any time. However, this will not prevent our Site
        from placing further Cookies on your device unless and until you
        adjust your &quot;Cookie Preferences&quot; choice as described above.
        </p>

        <h3 className="font-bold text-md mt-6 mb-2">Contact Details</h3>
        <p className="text-sct-grey mb-2">
        If you have any other questions or comments about our Cookie Policy, Please <a className='cursor-pointer' onClick={() => navigate('/contactus')} >Contact us</a>
        </p>
      </div>
    </div>
  );
};

export default CookieDeclaration;
