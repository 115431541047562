import React from 'react';

const HeroSection = () => {
  return (
    <section className="relative mt-8 mb-8">
      <div className="container flex flex-col-reverse lg:flex-row items-center gap-12">
        {/* Content */}
        <div className="flex flex-1 flex-col items-center lg:items-start">
          <h2 className="text-sct-primary text-xl md:text-2xl lg:text-3xl text-center lg:text-left mb-6 font-semibold">
            About Us
          </h2>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            As your business grows, evolves, and improves every day, an intentional and well-executed IT strategy is an essential component in maintaining a sustained competitive advantage. It’s difficult to keep up with the latest technology trends, but with insightful advice and focused professional guidance from Source Code Technologies LLC, you can seamlessly navigate every stage of growth, from start-up to IPO. Get in touch with us today to learn more about how we can help your organization maximize your IT strategy.
          </p>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            Your success is our top priority, and our team works with honesty, integrity, and sincerity to ensure our solutions are a perfect match for your organizational IT needs. We’ll help you move past legacy technology that may be a barrier to productivity, replacing it with advanced solutions that are tailored to provide exactly what your organization needs to excel.
          </p>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            With innovative strategies that encourage rapid decision-making, teamwork, and proactive problem resolution, Source Code Technologies LLC will help position your organization to achieve greater success than you’ve ever imagined.
          </p>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            As a premier IT services company, we believe in maintaining a positive mindset and creating partnerships with purpose. We strive to deliver significant outcomes with measurable results that consistently drive the success and growth of our clients.
          </p>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            Contact us today for an initial consultation.
          </p>
        </div>
        {/* Image */}
        <div className="flex justify-center flex-1 z-10">
          <img
            className="w-5/6 h-5/6 sm:w-3/4 sm:h-3/4 md:w-full md:h-full"
            src={require('../../../assets/images/hero_new.png')}
          />
        </div>
        <div className="hidden md:block overflow-hidden bg-sct-primary rounded-l-full absolute h-80 w-2/4 top-32 right-0 lg:-bottom-28 lg:-right-26"></div>
      </div>
    </section>
  );
};

export default HeroSection;
