import React from "react";
import Banner from "../../components/UI/Banner";
import DataAnalyticsHeroSection from "../../components/UI/DataAnalyticsHeroSection";


const DataAnalytics = () => {
  return (
    <div className="scroll-smooth">
      <Banner
        imgUrl={require("../../assets/images/clients/Data-analysis-banner.png")}
        heading="Data Analytics"
      />
      <div>
        <DataAnalyticsHeroSection />
        {/*<DataAnalyticsStrategySection />
        <DataAnalyticsManagementSection />*/}
      </div>
    </div>
  );
};

export default DataAnalytics;
