import React from 'react';
import Banner from '../../components/UI/Banner';
import { useNavigate } from 'react-router-dom';

const TermsAndConditions = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Banner
        imgUrl="https://images.unsplash.com/photo-1604178737169-001c71f9d6b8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1171&q=80"
        heading="Terms And Conditions"
      />
      <div className="container my-16">
        <p className="text-sct-grey mb-2">
          These Terms of Use (the "Terms", "Terms of Use") applies to{' '}
          <a className="cursor-pointer" onClick={() => navigate('/')}>
            https://www.sourcecodetecno.com
          </a>
          , the website (our "Service", our “Site”) operated by Source Code
          Technologies (“Sourcecodetecno”,"us", "we", or "our"). Please read
          these Terms of Use carefully before you start to use our Site, as
          these will apply to your use of our Site. By using our Site, you
          confirm that you accept these Terms of Use and that you agree to
          comply with them. If you do not agree to these Terms of Use, please
          refrain from using our Site.
        </p>
        <p className="text-sct-grey mb-2">
          We reserve the right, at our sole discretion, to change, modify, add
          or remove portions of these Terms of Use, at any time. It is your
          responsibility to check these Terms of Use periodically for changes.
          Your continued use of our Site following the posting of changes will
          mean that you accept and agree to the changes. As long as you comply
          with these Terms of Use, we grant you a personal, non-exclusive,
          non-transferable, limited privilege to enter and use our Site.
        </p>
        <h3 className="font-bold text-md mt-6 mb-2">Security</h3>
        <p className="text-sct-grey mb-2">
          Our Site uses SSL (Secure Sockets Layer protocol) to protect your
          personal information. SSL is a security function for the purpose of
          protecting personal information and other important information
          exchanged over the Internet from interception by third parties.
        </p>
        <h3 className="font-bold text-md mt-6 mb-2">Personal Information</h3>
        <p className="text-sct-grey mb-2">
          Our Privacy Policy applies to use of our Site, and the Terms are made
          a part of these Terms of Use by this reference. To view our Privacy
          Policy,
        </p>{' '}
        <a
          className="cursor-pointer"
          onClick={() => navigate('/privacy-statement')}
        >
          click here (Privacy statement link here)
        </a>
        .<h3 className="font-bold text-md mt-6 mb-2">Cookies</h3>
        <p className="text-sct-grey mb-2">
          Our Cookie Policy applies to use of our Site, and the Terms are made a
          part of these Terms of Use by this reference. To view Our Cookie
          Policy,
        </p>{' '}
        <a
          className="cursor-pointer"
          onClick={() => navigate('/cookie-declaration')}
        >
          click here (Cookies declaration link here)
        </a>
        .
        <h3 className="font-bold text-md mt-6 mb-2">Duties of Our Site User</h3>
        <p className="text-sct-grey mb-2">
          In accessing or using our Site, you shall not:
          <ol className="list-decimal ml-4 text-sct-grey">
            <br />
            <li className="ml-3 mb-3">
              Harm other persons, in particular minors, or infringe their
              personal rights;
            </li>
            <li className="ml-3 mb-3">
              Breach public morality in its manner of use;
            </li>
            <li className="ml-3 mb-3">
              Violate any intellectual property right or any other proprietary
              right;
            </li>
            <li className="ml-3 mb-3">
              Upload any contents containing a virus, so-called Trojan Horse, or
              any other program that could damage data;
            </li>
            <li className="ml-3 mb-3">
              Transmit, store or upload hyperlinks or contents to which the User
              is not entitled, in particular in cases where such hyperlinks or
              contents are in breach of confidentiality obligations or unlawful;
              or
            </li>
            <li className="ml-3 mb-3">
              Distribute advertising or unsolicited e-mails (so-called "spam")
              or inaccurate warnings of viruses, defects or similar material and
              the User shall not solicit or request the participation in any
              lottery, snowball system, chain letter, pyramid game or similar
              activity.
            </li>
          </ol>
        </p>
        <p className="text-sct-grey mb-2">
          We may deny access to our Site at any time, in particular if you
          breach any obligation arising from these Terms of Use.
        </p>
        <p className="text-sct-grey mb-2">
          We reserve the right at all times to disclose any information that we
          deem necessary to comply with any applicable law, regulation, legal
          process or governmental request. We also may disclose your information
          when we determine that applicable law requires or permits such
          disclosure, including exchanging information with other companies and
          organizations for fraud protection purposes.
        </p>
        <p className="text-sct-grey mb-2">
          You also agree that any violation by you of these Terms of Use will
          constitute an unlawful and unfair business practice, and will cause
          irreparable harm to us, for which monetary damages would be
          inadequate, and you consent to our obtaining any injunctive or
          equitable relief that we deem necessary or appropriate in such
          circumstances. These remedies are in addition to any other remedies we
          may have at law or in equity.
        </p>
        <h3 className="font-bold text-md mt-6 mb-2">Linking to our Site</h3>
        <p className="text-sct-grey mb-2">
          Operators of other websites are, as a general rule, free to include
          links to our Site. However, we assume no responsibility for the
          content of any third-party websites linked to or from our Site. The
          scope of our Site is limited to pages with the domain name
          “nttdata.com”. Links to pages outside this scope should be made only
          after confirming the policy of the respective sites.
          <br />
          <br />
          The following practices are strictly forbidden.
        </p>
        <ol className="list-decimal ml-4 text-sct-grey">
          <li className="ml-3 mb-3">
            Linking to our Site from a website with content slanderous of or
            otherwise injurious to the company or its products or harmful to its
            reputation.
          </li>
          <li className="ml-3 mb-3">
            Linking to our Site from a website with content that goes against
            the public order and morals.
          </li>
          <li className="ml-3 mb-3">
            Linking to our Site from a website containing illegal content, or
            involved in or likely to be involved in illegal activity.
          </li>
          <li className="ml-3 mb-3">
            Using a frame or other means to disguise the fact that the displayed
            content is from our Site.
          </li>
        </ol>
        <h3 className="font-bold text-md mt-6 mb-2">
          Corporate Logo Guidelines
        </h3>
        <p className="text-sct-grey mb-2">
          Use of the Source Code Technologies LLC Corporate logo shall be agreed
          to abide by the following guidelines.
        </p>
        <ol className="list-decimal ml-4 text-sct-grey">
          <li className="ml-3 mb-3">
            The logo GIF image shall be used only for linking to our Site.
          </li>
          <li className="ml-3 mb-3">
            The user shall acknowledge the rights of Source Code Technologies to
            the logo and shall make no claims regarding those rights.
          </li>
          <li className="ml-3 mb-3">
            Use of the Source Code Technologies LLC logo to link to our Site
            does not entitle the owner of the linking website to any privilege
            or consideration by Source Code Technologies, nor shall any such
            representation be made.
          </li>
          <li className="ml-3 mb-3">
            The logo GIF image shall be used exactly in the form provided,
            without making any alterations.
          </li>
          <li className="ml-3 mb-3">
            We reserve the right to discontinue allowing the use of Source Code
            Technologies LLC Corporate logo at any time, and has the right to
            alter its shape and design.
          </li>
          <li className="ml-3 mb-3">
            When using the Source Code Technologies LLC Corporate logo GIF image
            for making links to our Site, please observe the following use
            conditions and guidelines.
          </li>
          <li className="ml-3 mb-3">
            You must use the following HTML code and refer to us as either
            “SOURCE CODE TECHNOLOGIES LLC” or “SOURCE CODE TECHNOLOGIES
            Corporation” when using the Corporate Name to link to our website.
            <a className="cursor-pointer" onClick={() => navigate('/')}>
              https://www.sourcecodetecno.com
            </a>
          </li>
        </ol>
        <h3 className="font-bold text-md mt-6 mb-2">Disclaimers</h3>
        <p className="text-sct-grey mb-2">
          The content on our Site is provided for general information only.
          Although we make reasonable efforts to update the information on our
          Site, we make no representations, warranties or guarantees, whether
          express or implied, that the content on our Site is accurate, complete
          or up to date.
        </p>
        <p className="text-sct-grey mb-2">
          We may update and change our Site from time to time. We do not
          guarantee that our Site, or any content on it, will always be
          available or be uninterrupted. We may suspend, withdraw or restrict
          the availability of all or any part of our Site for business and
          operational reasons.
        </p>
        <p className="text-sct-grey mb-2">
          We are not responsible for websites we link to. Where our Site
          contains links to other sites and resources provided by third parties,
          these links are provided for your information only. Such links should
          not be interpreted as approval by us of those linked websites or
          information you may obtain from them.
        </p>
        <p className="text-sct-grey mb-2">
          By using our Site, you acknowledge and agree that Internet
          transmissions are never completely private or secure. You understand
          that any message or information you send to our Site may be read or
          intercepted by others, even if there is a special notice that a
          particular transmission (for example, credit card information) is
          encrypted.
        </p>
        <p className="text-sct-grey mb-2">
          We will not be liable to you for any loss or damage, arising out of or
          in connection with your access to, or use of our Site, its features or
          any information made available through our Site, even if advised in
          advance of such damages or losses, to the extent permitted by
          applicable law.
        </p>
        <p className="text-sct-grey mb-2">
          <b>
            WE DO NOT PROMISE THAT OUR SITE OR ANY CONTENT, SERVICE OR FEATURE
            OF OUR SITE WILL BE ERROR-FREE OR UNINTERRUPTED, OR THAT ANY DEFECTS
            WILL BE CORRECTED, OR THAT YOUR USE OF OUR SITE WILL PROVIDE
            SPECIFIC RESULTS. OUR SITE AND ITS CONTENT ARE DELIVERED ON AN
            "AS-IS" AND "AS-AVAILABLE" BASIS. ALL INFORMATION PROVIDED ON OUR
            SITE IS SUBJECT TO CHANGE WITHOUT NOTICE. WE CANNOT ENSURE THAT ANY
            FILES OR OTHER DATA YOU DOWNLOAD FROM OUR SITE WILL BE FREE OF
            VIRUSES OR CONTAMINATION OR DESTRUCTIVE FEATURES. WE DISCLAIM ALL
            WARRANTIES, EXPRESS OR IMPLIED, INCLUDING ANY WARRANTIES OF
            ACCURACY, NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A
            PARTICULAR PURPOSE. WE DISCLAIM ANY AND ALL LIABILITY FOR THE ACTS,
            OMISSIONS AND CONDUCT OF ANY THIRD PARTIES IN CONNECTION WITH OR
            RELATED TO YOUR USE OF OUR SITE AND/OR ANY OUR SERVICES. YOU ASSUME
            TOTAL RESPONSIBILITY FOR YOUR USE OF OUR SITE AND ANY LINKED SITES.
            YOUR SOLE REMEDY AGAINST US FOR DISSATISFACTION WITH OUR SITE OR ANY
            CONTENT IS TO STOP USING OUR SITE OR ANY SUCH CONTENT. THIS
            LIMITATION OF RELIEF IS A PART OF THE BARGAIN BETWEEN THE PARTIES.
          </b>
        </p>
        <p className="text-sct-grey mb-2">
          The above disclaimer applies to any damages, liability or injuries
          caused by any failure of performance, error, omission, interruption,
          deletion, defect, delay in operation or transmission, computer virus,
          communication line failure, theft or destruction of or unauthorized
          access to, alteration of, or use, whether for breach of contract,
          tort, negligence or any other cause of action.
          <br />
          <br />
          We reserve the right to do any of the following, at any time, without
          notice:
        </p>
        <ol className="list-decimal ml-4 text-sct-grey">
          <li className="ml-3 mb-3">
            to modify, suspend or terminate operation of or access to our Site,
            or any portion of our Site, for any reason.
          </li>
          <li className="ml-3 mb-3">
            to modify or change our Site, or any portion of our Site, and any
            applicable policies or Terms
          </li>
          <li className="ml-3 mb-3">
            to interrupt the operation of our Site, or any portion of our Site,
            as necessary to perform routine or non-routine maintenance, error
            correction, or other changes.
          </li>
        </ol>
        <h3 className="font-bold text-md mt-6 mb-2">Limitation of Liability</h3>
        <p className="text-sct-grey mb-2">
          Except where prohibited by law, in no event will SOURCE CODE
          TECHNOLOGIES LLC be liable to you for any indirect, consequential,
          exemplary, incidental or punitive damages, including lost profits,
          even if we have been advised of the possibility of such damages.
        </p>
        <h3 className="font-bold text-md mt-6 mb-2">Indemnity</h3>
        <p className="text-sct-grey mb-2">
          You agree to indemnify and hold SOURCE CODE TECHNOLOGIES LLC, its
          officers, directors, shareholders, predecessors, successors in
          interest, employees, agents, subsidiaries and affiliates, harmless
          from any demands, loss, liability, claims or expenses (including
          attorneys’ fees), made against us by any third party due to or arising
          out of or in connection with your use of our Site.
        </p>
        <h3 className="font-bold text-md mt-6 mb-2">
          Intellectual Property Rights
        </h3>
        <p className="text-sct-grey mb-2">
          Unless specifically stated to the contrary, copyright, trademark,
          brand names and other contents of all documents, images, pictures,
          software, and other content provided on our Site (“Intellectual
          Property”) belongs to SOURCE CODE TECHNOLOGIES LLC Corporation or to
          third parties that have granted rights thereof to SOURCE CODE
          TECHNOLOGIES. The handling or use of the Intellectual Property is
          subject to SOURCE CODE TECHNOLOGIES Copyright Policy. To view Our
          SOURCE CODE TECHNOLOGIES Copyright Policy, click here.
        </p>
        <h3 className="font-bold text-md mt-6 mb-2">Miscellaneous</h3>
        <p className="text-sct-grey mb-2">
          If any of the provisions of these Terms of Use are held by a court or
          other tribunal of competent jurisdiction to be void or unenforceable,
          such provisions shall be limited or eliminated to the minimum extent
          necessary and replaced with a valid provision that best embodies the
          intent of these Terms of Use, so that these Terms of Use shall remain
          in full force and effect.
        </p>
        <p className="text-sct-grey mb-2">
          These Terms of Use constitute the entire agreement between you and
          SOURCE CODE TECHNOLOGIES LLC with regard to your use of our Site, and
          any and all other written or oral agreements or understandings
          previously existing between you and SOURCE CODE TECHNOLOGIES with
          respect to such use are hereby superseded and cancelled. These Terms
          of Use shall not be interpreted or construed to confer any rights or
          remedies on any third parties.
        </p>
        <h3 className="font-bold text-md mt-6 mb-2">
          Applicable Laws and Jurisdiction
        </h3>
        <p className="text-sct-grey mb-2">
          These Terms of Use shall be governed by - and all disputes relating to
          or in connection with these Terms of Use or their subject matter shall
          be resolved in accordance with - the laws of state of California, to
          the exclusion of its conflict of laws rules.
        </p>
        <h3 className="font-bold text-md mt-6 mb-2">
          Privacy notice for California residents{' '}
        </h3>
        <p className="text-sct-grey mb-2">
          If you are a California resident, you can learn more about your
          consumer privacy rights by reviewing the{' '}
          <a
            className="cursor-pointer"
            href="https://oag.ca.gov/privacy"
            target="_blank"
          >
            California Privacy Notice.
          </a>
        </p>
      </div>
    </div>
  );
};

export default TermsAndConditions;
