import React from 'react';

const CloudConsultingHome = () => {
  return (
    <section className="relative my-16">
      <div className="container flex flex-col-reverse lg:flex-row items-center gap-12">
        {/* Content */}
        <div className="flex flex-1 flex-col items-center lg:items-start">
          <h2 className="text-sct-primary text-xl md:text-2xl lg:text-3xl text-center lg:text-left mb-6 font-semibold">
            Cloud Migration
          </h2>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            Bring industrialized cloud services together with patented tools and automation that speed migration,
            provides data sovereignty and mitigate risks.
          </p>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            Understanding what cloud migration is, how it can benefit your business, and what is involved,
            will help you decide on the best strategy to achieve a smooth transition to the cloud.
          </p>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            Our comprehensive cloud migration framework brings industrialized capabilities together with exclusive pre-configured industry-specific tools,
            methods, and automation across all cloud models and multiple delivery methods (IaaS, PaaS, and SaaS).
          </p>
        </div>
        {/* Image */}
        <div className="flex justify-center flex-1 z-10">
          <img
            className="w-5/6 h-5/6 sm:w-3/4 sm:h-3/4 md:w-full md:h-full"
            src={require('../../../assets/images/clients/cloudmigration.png')}
          />
        </div>
      </div>
      <div className="container flex flex-col-reverse lg:flex-row-reverse items-center gap-12">
        {/* Content */}
        <div className="flex flex-1 flex-col items-center lg:items-start">
          <h2 className="text-sct-primary text-xl md:text-2xl lg:text-3xl text-center lg:text-left mb-6 font-semibold">
            Data transformation
          </h2>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            Migrating data to cloud, while a significant effort for many, is only the first step.
            Migration opens the door to the much larger opportunity
            of creating a trusted data platform across disparate parts of the business.
            A value creation platform that can be used to augment and reinvent operational workflows,
            activate more informed and engaging customer and employee experiences, and fuel the next waves of product and market growth.
          </p>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            Assess current data maturity and capability, develop architecture and roadmap.Stand up cloud services, build a data foundation and data products.
            Automate data governance, infuse AI/ML into data management.
          </p>
        </div>
        {/* Image */}
        <div className="flex justify-center flex-1 z-10">
          <img
            className="w-5/6 h-5/6 sm:w-3/4 sm:h-3/4 md:w-full md:h-full"
            src={require('../../../assets/images/clients/clouddatatransfer.png')}
          />
        </div>
      </div>

      <div className="container flex flex-col-reverse lg:flex-row items-center gap-12">
        {/* Content */}
        <div className="flex flex-1 flex-col items-center lg:items-start">
          <h2 className="text-sct-primary text-xl md:text-2xl lg:text-3xl text-center lg:text-left mb-6 font-semibold">
            Infrastructure Services
          </h2>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            Leverage hybrid cloud or reinvent your networks and workplace experience to accelerate cloud’s value.
          </p>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            In today’s digital and cloud-based world, your business performs only as well as your IT infrastructure.
            It empowers the users and runs the applications that run the business. A platform on which to build exceptional
            customer and employee experiences.
          </p>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            Yet traditional infrastructures cannot keep pace with accelerated change and compressed transformation.
            IT infrastructure must modernize. We can help you move away from a capital-intensive, hardware-oriented
            infrastructure toward a software-defined and intelligent one - ever-ready for any opportunity.
          </p>
        </div>
        {/* Image */}
        <div className="flex justify-center flex-1 z-10">
          <img
            className="w-5/6 h-5/6 sm:w-3/4 sm:h-3/4 md:w-full md:h-full"
            src={require('../../../assets/images/clients/cloudinfra.png')}
          />
        </div>
      </div>

      <div className="container flex flex-col-reverse lg:flex-row-reverse items-center gap-12">
        {/* Content */}
        <div className="flex flex-1 flex-col items-center lg:items-start">
          <h2 className="text-sct-primary text-xl md:text-2xl lg:text-3xl text-center lg:text-left mb-6 font-semibold">
            Cloud Security
          </h2>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            Protect your IT estate with our cloud security services.
          </p>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            While cloud offers new opportunities to modernize services and transform operations, security and
            compliance risk remains the greatest barrier to cloud adoption. Combined with the complexity of hybrid-
            and multi-cloud environments and a shortage of skills, these concerns can be major roadblocks to a cloud-first journey.
          </p>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            High-profile security breaches have raised issues around the role of security in any cloud journey.
            But cloud security can enable better business outcomes by being:
          </p>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            <b>Fast:</b> With cloud service provider (CSP)-native accelerators that enable security capabilities and controls to be deployed in minutes or hours, rather than months.
          </p>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            <b>Frictionless:</b> With security embedded in existing solutions, business processes, and operational teams.
          </p>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            <b>Scalable:</b>  With automation and self-healing processes applied to reduce manual steps and break the resourcing model of adding headcount to enable organizations to scale.
          </p>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            <b>Proactive:</b> With pre-emptive controls established to block accidental or malicious security incidents from happening in the first place.
          </p>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            <b>Cost effective:</b> Bake-in security from the outset to avoid the additional costs incurred by having to re-do work.
          </p>
        </div>
        {/* Image */}
        <div className="flex justify-center flex-1 z-10">
          <img
            className="w-5/6 h-5/6 sm:w-3/4 sm:h-3/4 md:w-full md:h-full"
            src={require('../../../assets/images/clients/cloudsecurity.png')}
          />
        </div>
      </div>
    </section>
  );
};

export default CloudConsultingHome;
