import React from "react";
import FeatureCards from "../FeatureCards";

const OurFeatures = () => {
  return (
    <section className="bg-sct-white py-20">
      <div className="sm:w-3/4 mx-auto px-2">
        <h1 className="text-3xl text-center text-sct-primary font-semibold">
          How we stand out ?
        </h1>
        <p className="text-center text-sct-grey mt-4">
          We are a start-up company having group of enthusiastic engineers with
          a core engineering mindset rather than a normal delivery attitude. Our
          key differentiator lies in the fact that we understand our customer's
          business problems and provide end-to-end IT Solutions.
        </p>
        <p className="text-center text-sct-grey mt-4">
          We believe in quick & best quality deliverables with less code. We have
          a large pool of knowledge that we apply to deliver solutions that meet
          client's needs, expectations and budget.
        </p>
      </div>

      {/* Cards */}
      <FeatureCards />
    </section>
  );
};

export default OurFeatures;
