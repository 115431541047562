import React from "react";
import Banner from "../../components/UI/Banner";
import TestimonialCard from "../../components/UI/TestimonialCard";

const Testimonials = () => {
  const testimonialCardData = [
    {
      imageUrl: require("../../assets/images/clients/vaco.png"),
      name: "Josh Grummel",
      designation: "Associate, Consulting Services, Vaco",
      review:
        "Source code Technologies is a fantastic source for top-notch candidates. Reqs open, close quickly and they are quick to the punch. Not only are their consultants excellent Technology folks but also good communicators, they've proven to be diligent employees. I highly recommend them for talent buyers looking for IT professionals with cutting-edge skills",
    },
    {
      imageUrl: require("../../assets/images/clients/kforce1.png"),
      name: "KForce",
      designation: "Strategic Delivery Manager, KForce",
      review:
        "I was impressed by Source Code's ability to generate trust with partners and their fluidity to solve any problems.",
    },
    {
      imageUrl: require("../../assets/images/clients/eliassen.jpg"),
      name: "Matt Sidebottom ",
      designation: "Senior Technical Recruiter, Eliassen Group",
      review:
        "Compared to other firms I’ve worked with, Source Code Technologies has done a better job at engaging us early in the process. They fully respect our process and guidelines, too.",
    },
    {
      imageUrl: require("../../assets/images/clients/pyramid1.png"),
      name: "Pyramid Consulting, Inc",
      designation: "Senior Executive Resourcing, Pyramid Consulting, Inc",
      review:
        "Source Code Technologies is very professional in the way they communicate and act.",
    },
    {
      imageUrl: require("../../assets/images/clients/resilent_card.png"),
      name: "Stephanie Keaton",
      designation: "Director Resource Management, ResiliEnt Business Solutions",
      review: `ResiliEnt Business Solutions has worked with Source Code Technologies for just over a year and could not be more pleased with the level of professionalism and responsiveness to any inquiry we have. Kumar and his team will not disappoint if you are looking for qualified consultants for information technology roles.`,
    },
    {
      imageUrl: require("../../assets/images/clients/datrix.jpg"),
      name: "Datrix AI",
      designation: "Director,  AI/ML Consulting firm, Datrix AI",
      review:
        "It's truly impressive how fast Source Code Technologies adapted their methodology, architecture and infrastructure for our exact needs in AI Implementations.",
    },
    {
      imageUrl:
        "https://images.unsplash.com/photo-1640622332859-55e65253475d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDF8MHxlZGl0b3JpYWwtZmVlZHw2fHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=500&q=60",
      name: "Bhagya",
      designation: "CEO, Data & Analytics, Techno Techie Consulting",
      review:
        "We began working with Source Code Technologies because we were facing a lot of challenges within our organisation. We were not organized, we were not effectively using our existing tools and we were not delivering projects to the clients on time.Overall Source code has a great resources of consultants and I would recommend it for any new Technology implementations.",
    },
  ];
  return (
    <div className="scroll-smooth">
      <Banner
        imgUrl={require("../../assets/images/clients/rating.jpg")}
        heading="Testimonials"
      />
      <div className="container my-16">
        {testimonialCardData.map((data) => {
          return (
            <TestimonialCard
              imageUrl={data.imageUrl}
              name={data.name}
              designation={data.designation}
              review={data.review}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Testimonials;
