import React from 'react';
import Banner from '../../components/UI/Banner';

const PrivacyStatement = () => {
  return (
    <div>
      <Banner
        imgUrl={require('../../assets/images/clients/privacy.jpg')}
        heading="Privacy Statement"
      />
      <div className="container my-16">
        <h3 className="font-bold text-md mt-6 mb-2">
          Source Code Technologies LLC’s Personal Information Protection Policy{' '}
        </h3>
        <p className="text-sct-grey mb-2">
          SOURCE CODE TECHNOLOGIES LLC recognizes the importance of personal
          information and ensures the protection and safeguarding of its
          customers’ personal information as a fundamental principle of its
          business and its responsibility to society. Accordingly, SOURCE CODE
          TECHNOLOGIES has established the Personal Information Protection
          Policy described below and ensures that all its officers, employees,
          and business partners thoroughly understand and fully comply with the
          Policy.
        </p>

        <h3 className="font-bold text-md mt-6 mb-2">
          Collection, Use, Provision, and Entrustment of Personal Information
        </h3>
        <ol className="list-disc ml-4 text-sct-grey">
          <li className="mb-2">
            Personal information shall be collected only after informing the
            customers of the purpose and obtaining consent for doing so, except
            when the rights or interests of customers or third parties may be
            injured. The scope of use of the personal information collected is
            limited, and the information shall be handled appropriately within
            this scope.
          </li>
          <li className="mb-2">
            Personal information collected by SOURCE CODE TECHNOLOGIES LLC shall
            not be provided to third parties without prior express consent of
            the customers, except as required by law or regulation.
          </li>
          <li className="mb-2">
            SOURCE CODE TECHNOLOGIES may entrust customers’ personal information
            with authorized third parties. In all such cases, SOURCE CODE
            TECHNOLOGIES shall select the recipient with great care from among
            those parties that have established a sufficient level of protection
            of the personal information, and make necessary supervision or
            otherwise ensure, through a contract, etc. stipulating the level of
            protection to be observed, that the recipient manages the
            information properly.
          </li>
        </ol>

        <h3 className="font-bold text-md mt-6 mb-2">
          Disclosure, Correction, Discontinuance of Use, etc. of Personal
          Information
        </h3>
        <p className="text-sct-grey mb-2">
          SOURCE CODE TECHNOLOGIES LLC implements safeguard measures and
          diligently works toward preventing unauthorized access to personal
          information, and the loss, destruction, alteration, leakage, etc. of
          such information. Specific rules governing the appropriate handling
          and use of personal information are in place, and staff are assigned
          to take responsibility therefor.
        </p>

        <h3 className="font-bold text-md mt-6 mb-2">
          Observance of Laws, Regulations and Other Norms Relating to Personal
          Information
        </h3>
        <p className="text-sct-grey mb-2">
          With respect to the observance of laws, regulations, and other norms
          relating to personal information, SOURCE CODE TECHNOLOGIES LLC’s
          officers, employees, and business partners who handle personal
          information comply with the laws, regulations, and guidelines relating
          to the protection of personal information and the privacy of
          communication.
        </p>

        <h3 className="font-bold text-md mt-6 mb-2">
          Continuous improvements to this Personal Information Protection Policy
          and In-house Rules and Regulations
        </h3>
        <p className="text-sct-grey mb-2">
          SOURCE CODE TECHNOLOGIES LLC makes continuous improvements to this
          Personal Information Protection Policy and in-house rules and
          regulations to ensure that the protection of personal information as
          determined by socially accepted norms is conducted effectively.
        </p>

        <h3 className="font-bold text-md mt-6 mb-2">
          Inquiries as to Personal Information
        </h3>
        <p className="text-sct-grey mb-2">
          SOURCE CODE TECHNOLOGIES LLC will make every effort to respond to
          inquiries promptly within a reasonable scope.
          <br />
          <b>
            Please contact SOURCE CODE TECHNOLOGIES LLC’s customer service.
            <br />
            Established: April 29, 2021
            <br />
            Revised: Feburary19, 2022
            <br />
            J Pallaboina
            <br />
            President and Chief Executive Officer
            <br />
          </b>
        </p>

        <p className="text-sct-grey mb-2">
          <b>
            For more information, please contact:
            <br />
            SOURCE CODE TECHNOLOGIES LLC’s Customer Desk
            <br />
            177 Cadence, Irvine, CA 92618
            <br />
            E-MAIL : admin sourcecodetecno.com
            <br />
          </b>
          SOURCE CODE TECHNOLOGIES LLC may revise the Policy set out above and
          issue notification of all revisions on this website. <br />
        </p>

        <h3 className="font-bold text-md mt-6 mb-2">
          The Use of personal information of the Site visitors{' '}
        </h3>
        <p className="text-sct-grey mb-2">
          This website is operated for the purpose of providing information
          about SOURCE CODE TECHNOLOGIES LLC. Certain parts of the site request
          its visitors to provide personal information for the purposes of
          responding to inquiries. Please note that it may be necessary, for the
          above purposes, to provide such information to a third party to enable
          it to provide answer and delivery services directly to the site
          visitors.
        </p>

        <ol className="list-decimal ml-4 text-sct-grey">
          <li className="ml-3 mb-3">
            <b>
              Purposes of information provision to a third party
              <br />
            </b>
            <ol className="list-disc ml-4 text-sct-grey">
              <li className="mb-2">
                SOURCE CODE TECHNOLOGIES LLC may, for the above purposes,
                disclose personal information provided at the discretion of the
                site visitors. Except in this case, it shall not provide such
                information to a third party without the prior consent of the
                site visitors. However, the following cases will make an
                exception.
              </li>
              <li className="mb-2">
                In the case where the provision of such information is ordered
                by the court or governmental agencies, national or local, in
                accordance with the stipulation of laws or regulations.
              </li>
              <li className="mb-2">
                In the case where it is necessitated for the purpose of
                protection of rights, property, security, etc. of the site
                visitors and SOURCE CODE TECHNOLOGIES LLC.
              </li>
            </ol>
          </li>
          <li className="ml-3 mb-3">
            <b>Items of personal information</b>
            <ol className="list-disc ml-4 text-sct-grey">
              <li className="mb-2">
                Your name, your e-mail address, your company name, your
                relationships with SOURCE CODE TECHNOLOGIES LLC and your country
                name
              </li>
            </ol>
          </li>
          <li className="ml-3 mb-3">
            <b>Means or media of provision</b>
            <ol className="list-disc ml-4 text-sct-grey">
              <li className="mb-2">Internet delivery</li>
              <li className="mb-2">
                Provision in writing or through other media
              </li>
            </ol>
          </li>
          <li className="ml-3 mb-3">
            <b>Party to which personal information is provided</b>
            <ol className="list-disc ml-4 text-sct-grey">
              <li className="mb-2">
                The party designated by SOURCE CODE TECHNOLOGIES LLC
              </li>
            </ol>
          </li>
          <li className="ml-3 mb-3">
            <b>
              Contract with the preceding party on handling personal information
            </b>
            <ol className="list-disc ml-4 text-sct-grey">
              <li className="mb-2">
                A contract has been made between SOURCE CODE TECHNOLOGIES LLC
                and the party on the handling of personal information.
              </li>
            </ol>
          </li>
        </ol>

        <p className="text-sct-grey mb-2">
          <b>
            Please note that if we are provided with inaccurate personal
            information by the site visitors, we may be unable to respond to
            their inquiries, etc.
          </b>
        </p>
      </div>
    </div>
  );
};

export default PrivacyStatement;
