import React from 'react';

const TestimonialCard = (props) => {
  return (
    <figure className="md:flex bg-sct-white rounded-xl p-8 md:p-0 overflow-hidden mb-8">
      <img
        className="w-32 h-32 object-cover md:w-48 md:h-auto md:rounded-none rounded-full mx-auto md:mx-0"
        src={props.imageUrl}
      />
      <div className="pt-6 md:p-8 text-center md:text-left space-y-4">
        <blockquote>
          <p className="text-lg font-medium">“{props.review}”</p>
        </blockquote>
        <figcaption className="font-medium">
          <div className="text-sct-secondary">{props.name}</div>
          <div className="text-sct-grey">{props.designation}</div>
        </figcaption>
      </div>
    </figure>
  );
};

export default TestimonialCard;
