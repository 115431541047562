import React from 'react';

const AboutJob = (props) => {
  const jobData = props.jobData;
  return (
    <div className="relative mb-16">
      <div className="container">
        <h3 className="font-bold mt-2">What you’ll do on the job</h3>
        <ul className="list-disc ml-10 mt-4">
          {jobData.jobDescription.map((jobDesc, index) => {
            return <li key={index}>{jobDesc}</li>;
          })}
        </ul>
        <h3 className="font-bold mt-2">You'll meet our requirements</h3>
        <ul className="list-disc ml-10 mt-4">
          {jobData.requirements.map((requirement, index) => {
            return <li key={index}>{requirement}</li>;
          })}
        </ul>
        <h3 className="font-bold mt-2">Perks:</h3>
        <ul className="list-disc ml-10 mt-4">
          {jobData.perks.map((perk, index) => {
            return <li key={index}>{perk}</li>;
          })}
        </ul>
        <p className="mt-4">
          If this excites you, apply for this opportunity, and the team would
          love to get on a call with you to discuss further.
        </p>
      </div>
    </div>
  );
};

export default AboutJob;
