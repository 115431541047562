import React from 'react';

const Banner = (props) => {
  return (
    <div className="h-72 md:h-60 w-full relative flex justify-center md:justify-start items-center">
      <img src={props.imgUrl} className="h-72 md:h-60 w-full object-cover" />
      {props.heading && (
        <div className="absolute bg-black/75 rounded-md md:left-10 mx-4 text-center md:text-left md:mx-4">
          <div className="p-6">
            <h1 className="text-4xl text-sct-white">{props.heading}</h1>
          </div>
        </div>
      )}
    </div>
  );
};

export default Banner;
