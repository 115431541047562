import React from 'react';

const TestingHeroSection = () => {
  return (
    <section className="relative my-16">
      <div className="container flex flex-col-reverse lg:flex-row items-center gap-12">
        {/* Content */}
        <div className="flex flex-1 flex-col items-center lg:items-start">
          <h2 className="text-sct-primary text-xl md:text-2xl lg:text-3xl text-center lg:text-left mb-6 font-semibold">
            Test Automation
          </h2>

          <p className="text-sct-grey text-center lg:text-left mb-6">
            Test automation is the process of using automation tools to maintain test data, execute tests, and analyze test results
            to improve software quality.
          </p>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            Our end-to-end test automation services enable you to define and build enterprise test automation frameworks, implement test automation
            frameworks, migrate test automation tools and script automated test scripts.
          </p>

          <p className="text-sct-grey text-center lg:text-left mb-6">
            The world is changing and this is also applicable to the world of software development.
            New methodologies and new approaches are constantly appearing. Their main goal: reduce the time
            to market for new pieces of software. Test automation enables quicker testing with fewer manual errors.
          </p>

        </div>
        {/* Image */}
        <div className="flex justify-center flex-1 z-10">
          <img
            className="w-5/6 h-5/6 sm:w-3/4 sm:h-3/4 md:w-full md:h-full"
            src={require('../../../assets/images/clients/testing-hero.png')}
          />
        </div>
      </div>
      <div className="container flex flex-col-reverse lg:flex-row-reverse items-center gap-12">
        {/* Content */}
        <div className="flex flex-1 flex-col items-center lg:items-start">
          <h2 className="text-sct-primary text-xl md:text-2xl lg:text-3xl text-center lg:text-left mb-6 font-semibold">
            Why Software Testing Should be Automated
          </h2>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            Providing a consistent app experience across devices is critical. Users expect instant, seamless interactions.
            And every digital experience is a reflection of your brand
            — which is why mobile app testing should be an integral part of your overall testing strategy.
          </p>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            Agile and DevOps are the new models for modern software engineering. This has changed the way code is being developed,
            tested, and used by businesses and consumers.
            And almost every organization today offers some type of software (e.g., mobile banking app or an online flight check-in tool).
          </p>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            New software releases are being delivered faster and more regularly than ever. It’s become crucial for companies with any
            level of digital footprint to improve the efficiency and accuracy of their testing to keep up with their competitors.
            The mobile space is incredibly fragmented. Between devices, generations, and operating systems, there are thousands of
            permutations to test. But with mobile test automation, you can quickly scale your tests and boost coverage to accelerate delivery.
          </p>
        </div>
        {/* Image */}
        <div className="flex justify-center flex-1 z-10">
          <img
              className="w-5/6 h-5/6 sm:w-3/4 sm:h-3/4 md:w-full md:h-full"
              src={require('../../../assets/images/clients/testing-import.png')}
          />
        </div>
      </div>
    </section>
  );
};

export default TestingHeroSection;
