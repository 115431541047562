import './App.css';
import AppNavigator from './navigation/AppNavigator';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import ReduxThunk from 'redux-thunk';
import jobsReducer from './store/reducers/jobs';
import { Provider } from 'react-redux';

const rootReducer = combineReducers({
  jobs: jobsReducer,
});

const store = createStore(rootReducer, applyMiddleware(ReduxThunk));

function App() {
  return (
    <Provider store={store}>
      <AppNavigator />
    </Provider>
  );
}

export default App;
