import React from "react";
import "./style.css";
import { FaUsers, FaCompass, FaLightbulb } from "react-icons/fa";

const FeatureCards = () => {
  return (
    <div className="container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 max-w-screen-lg mt-16">
      {/* Card 1 */}
      <div className="flex flex-col rounded-md shadow-2xl shadow-sct-purple card-one">
        <div className="p-6 flex flex-col">
          <FaUsers size={32} className="text-white" />
          <h3 className="mt-5 mb-2 text-sct-white text-lg">
            <b>Deeper understanding of the customer base.</b>
          </h3>
          <p className="mb-2 text-sct-white font-light">
            Our expert developers spend ample time with the customers to
            understand the requirements & provide suggestions & value adds
            wherever possible. The customers are provided with frequent status
            reports and check points to know the actual progress of the project.
          </p>
        </div>
      </div>

      {/* Card 2 */}
      <div className="flex flex-col rounded-md shadow-2xl shadow-sct-purple card-two">
        <div className="p-6 flex flex-col">
          <FaCompass size={32} className="text-white" />
          <h3 className="mt-5 mb-2 text-sct-white text-lg">
            <b>Stepping up with the World of Technology.</b>
          </h3>
          <p className="mb-2 text-sct-white font-light">
            Our team of expert engineers always keep up with the latest and the
            greatest of the Technologies and excel them to become SMEs within no
            time.
          </p>
        </div>
      </div>

      {/* Card 3 */}
      <div className="flex flex-col rounded-md shadow-2xl shadow-sct-purple card-three">
        <div className="p-6 flex flex-col">
          <FaLightbulb size={32} className="text-white" />
          <h3 className="mt-5 mb-2 text-sct-white text-lg">
            <b>Cost effective Technology solutions.</b>
          </h3>
          <p className="mb-2 text-sct-white font-light">
            Although our competitors tries to bloat the costs, we always tend to
            keep it low so that it does not break the customer's account. We
            believe in long-term relationships with the customers and gain their
            trust quickly.
          </p>
        </div>
      </div>
    </div>
  );
};

export default FeatureCards;
