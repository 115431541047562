import React from 'react';
import { FaChevronRight } from 'react-icons/fa';
import './style.css';
import { useNavigate, useLocation, Link } from 'react-router-dom';

const CareerCard = (props) => {
  const navigate = useNavigate();
  return (
    <Link
      className="p-8 flex flex-col md:flex-row shadow-md my-8 transition-all hover:bg-sct-white cursor-pointer text-black"
      to={{
        pathname: `/careers/${props.jobId}`,
        state: {
          job: props.data,
        },
      }}
    >
      <div className="pr-8 basis-3/4 mb-2">
        <h2 className="text-xl font-semibold mb-2">{props.title}</h2>
        <p className="font-light text-limit">{props.description}</p>
      </div>
      <div className="basis-1/4 flex flex-row justify-center items-center border-t md:border-none">
        <div className="flex-auto my-2">
          <p className="font-bold">{props.location}</p>
          <p className="font-light">{props.jobType}</p>
        </div>
        <div className="mr-2">
          <FaChevronRight />
        </div>
      </div>
    </Link>
  );
};

export default CareerCard;
