import React from 'react';

const DatrixHeroSection = () => {
  return (
    <section className="relative my-16">
      <div className="container flex flex-col-reverse lg:flex-row items-center gap-12">
        {/* Content */}
        <div className="flex flex-1 flex-col items-center lg:items-start">
          <h2 className="text-sct-primary text-xl md:text-2xl lg:text-3xl text-center lg:text-left mb-6 font-semibold">
            Artificial intelligence - Consulting
          </h2>

          <p className="text-sct-grey text-center lg:text-left mb-6">
            We are experts in the field on machine learning and Deep learning,
            we have more than 8 years of professional experience in helping
            clients develop, deploy and scale AI algorithms. We apply cutting
            edge AI techniques on real world data and help businesses achieve
            their product goals. Reach out to us if you need scalable, safe and
            explainable AI consulting.
          </p>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            We provide cutting edge AI consulting to bridge the gap between
            research and real world applications in various streams. We use
            latest AI techniques in
            <ol className="list-disc ml-4 text-sct-grey">
              <br />
              <li className="ml-3 mb-3">
                Computer Vision - To analyze, interpret and generate images and
                videos.
              </li>
              <li className="ml-3 mb-3">
                Natural Language Processing - To analyze, interpret and generate
                text in any language.
              </li>
              <li className="ml-3 mb-3">
                Data Science - To analyze, interpret and provide insights into
                your data.
              </li>
              <li className="ml-3 mb-3">
                Time Series Analysis - To extract important patterns in the data
                and also to predict anomalies in the data.
              </li>
              <li className="ml-3 mb-3">
                Recommendation Systems - To recommend contents to the users
                based on their preferences.
              </li>
            </ol>
          </p>
        </div>
        {/* Image */}
        <div className="flex justify-center flex-1 z-10">
          <img
            className="w-5/6 h-5/6 sm:w-3/4 sm:h-3/4 md:w-full md:h-full"
            src={require('../../../assets/images/ai.png')}
          />
        </div>
      </div>
    </section>
  );
};

export default DatrixHeroSection;
