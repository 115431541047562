import React from "react";
import Banner from "../../components/UI/Banner";
import TestingHeroSection from "../../components/UI/TestingHeroSection";


const Testing = () => {
  return (
    <div className="scroll-smooth">
      <Banner
        imgUrl={require("../../assets/images/clients/testing-banner.png")}
        heading="Test Automation"
      />
      <div>
        <TestingHeroSection />
        {/*<DataAnalyticsStrategySection />
        <DataAnalyticsManagementSection />*/}
      </div>
    </div>
  );
};

export default Testing;
