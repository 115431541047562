import React, {useEffect} from 'react';
import ClientSection from '../../components/UI/ClientSection';
import HeroSection from '../../components/UI/HeroSection';
import MainCarousel from '../../components/UI/MainCarousel';
import OurFeatures from '../../components/UI/OurFeatures';
import TestimonialSlider from '../../components/UI/TestimonialSlider';
import CookieConsentPopup from '../../components/UI/CookieConsent';
import {useDispatch} from 'react-redux';
import * as jobsActions from '../../store/actions/jobs';
import TeamExpertise from "../../components/UI/TeamExpertise";
import OurProducts from "../../components/UI/OurProducts";

const Home = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(jobsActions.fetchJobs()).then(() => {});
    }, []);
    return (
        <div className="scroll-smooth">
            <MainCarousel />
            <HeroSection />
            <OurFeatures />
            <OurProducts/>
            <TeamExpertise/>
            <ClientSection />
            <TestimonialSlider />
            <CookieConsentPopup />
        </div>
    );
};

export default Home;
