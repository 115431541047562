import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import IScrum from '../screens/IScrum';
import Datrix from '../screens/Datrix';
import Home from '../screens/Home';
import Industries from '../screens/Industries';
import Services from '../screens/Services';
import ApplicationServices from '../screens/ApplicationServices';
import TechnologyConsulting from '../screens/TechnologyConsulting';
import TechnologyInnovation from '../screens/TechnologyInnovation';
import CloudConsulting from '../screens/CloudConsulting';
import Testimonials from '../screens/Testimonials';
import Careers from '../screens/Careers';
import ContactUs from '../screens/ContactUs';
import NavBar from '../components/shared/NavBar';
import Footer from '../components/shared/Footer';
import Error404 from '../screens/Error404';
import ScrollToTop from '../components/shared/ScrollToTop';
import ScrollToTopComponent from '../components/shared/ScrollToTopComponent';
import CareerDetails from '../screens/CareerDetails';
import NewNavBar from '../components/shared/NewNavBar';
import PrivacyStatement from '../screens/PrivacySatement';
import TermsAndConditions from '../screens/TermsAndConditions';
import CookieDeclaration from '../screens/CookieDeclaration';
import CopyrightPolicy from '../screens/CopyrightPolicy';
import Help from '../screens/Help';
import InProgress from '../screens/InProgress';
import DataAnalytics from "../screens/DataAnalytics";
import Testing from "../screens/Testing";
import Shopzilla from '../screens/Shopzilla';
import Investify from '../screens/Investify';


function AppNavigator() {
  return (
      <Router>
        <ScrollToTop />
        <ScrollToTopComponent />
        <NewNavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="products/i-scrumai" element={<IScrum />} />
          <Route path="products/datrix" element={<Datrix />} />
          <Route
              path="services/application-services"
              element={<ApplicationServices />}
          />
          <Route path="services/data-analytics" element={<DataAnalytics />} />
          <Route
              path="services/technology-consulting"
              element={<TechnologyConsulting />}
          />
          <Route path="services/datrix" element={<Datrix />} />
          <Route
              path="services/technology-innovation"
              element={<TechnologyInnovation />}
          />

          <Route path="services/cloud-consulting" element={<CloudConsulting />} />
          <Route path="services/testing" element={<Testing />} />
          <Route path="industries/automotive" element={<InProgress />} />
          <Route path="industries/banking" element={<InProgress />} />
          <Route path="industries/communications" element={<InProgress />} />
          <Route path="industries/capital-markets" element={<InProgress />} />
          <Route path="industries/consumer-goods" element={<InProgress />} />
          <Route path="industries/insurance" element={<InProgress />} />
          <Route path="industries/retail" element={<InProgress />} />
          <Route path="testimonials" element={<Testimonials />} />
          <Route path="careers" element={<Careers />} />
          <Route path="careers/:id" element={<CareerDetails />} />
          <Route path="contactus" element={<ContactUs />} />
          <Route path="privacy-statement" element={<PrivacyStatement />} />
          <Route path="terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="cookie-declaration" element={<CookieDeclaration />} />
          <Route path="copyright-policy" element={<CopyrightPolicy />} />
          <Route path="help" element={<Help />} />
          <Route path="inprogress" element={<InProgress />} />
          <Route path="products/shopzilla" element={<Shopzilla />} />
          <Route path="products/investify" element={<Investify />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
        <Footer />
      </Router>
  );
}

export default AppNavigator;
