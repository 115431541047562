import React from 'react';
import Banner from '../../components/UI/Banner';

const Help = () => {
  return (
    <div>
      <Banner
        imgUrl="https://images.unsplash.com/photo-1604178737169-001c71f9d6b8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1171&q=80"
        heading="Help"
      />
      <div className="container my-16">
        <p className="text-sct-grey mb-2">
          This privacy policy relates to the services offered by Laya Healthcare
          Ltd. We are committed to safeguarding the privacy of our patients and
          visitors to this website. We intend to fully comply with the Data
          Protection Act 1998 and General Data Protection Regulation.
        </p>
        <p className="text-sct-grey mb-2">
          This privacy policy relates to the services offered by Laya Healthcare
          Ltd. We are committed to safeguarding the privacy of our patients and
          visitors to this website. We intend to fully comply with the Data
          Protection Act 1998 and General Data Protection Regulation.
        </p>
        <h3 className="font-bold text-md mt-6 mb-2">Numbered Points</h3>
        <ol className="list-decimal ml-4 text-sct-grey">
          <li className="mb-2">
            Now this is a story all about how, my life got flipped turned upside
            down
          </li>
          <li className="mb-2">
            Now this is a story all about how, my life got flipped turned upside
            down
          </li>
        </ol>

        <h3 className="font-bold text-md mt-6 mb-2">Bulleted Points</h3>
        <ol className="list-disc ml-4 text-sct-grey">
          <li className="mb-2">
            Now this is a story all about how, my life got flipped turned upside
            down
          </li>
          <li className="mb-2">
            Now this is a story all about how, my life got flipped turned upside
            down
          </li>
        </ol>
      </div>
    </div>
  );
};

export default Help;
