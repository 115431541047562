import React from "react";
import Banner from "../../components/UI/Banner";
import ScrumHeroSection from "../../components/UI/ScrumHeroSection";
import ScrumChallengesSection from "../../components/UI/ScrumChallengesSection";
import ScrumSolution from "../../components/UI/ScrumSolution";
import TechnologyConsultingHome from "../../components/UI/TechnologyConsultingHome";

const TechnologyConsulting = () => {
  return (
    <div className="scroll-smooth">
      <Banner
        imgUrl={require("../../assets/images/clients/technology-banner.png")}
        heading="Technology Consulting"
      />
      <div>
        <TechnologyConsultingHome />
      </div>
    </div>
  );
};

export default TechnologyConsulting;
