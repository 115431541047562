import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';

const MainCarousel = () => {
  const carouselData = [
    {
      image: require('../../../assets/images/cali1.jpg'),
      legendQuote:
        'We always start our project implementation with a great architecture design.',
      legendBold: 'World-className IT Services.',
    },
    {
      image: require('../../../assets/images/office.jpg'),
      legendQuote:
        'We build software solutions, program modules for existing systems, extend ready software systems with new features.Let us know your requirements in business needs, our professional engineers shall be able to give advises and build based on what you request.',
    },
    {
      image: require('../../../assets/images/teamwork.jpg'),
      legendQuote:
        'Teamwork begins by building trust. And the only way to do that is to overcome our need for invulnerability.',
      legendBold: '- Patrick Lencioni',
    },
  ];
  return (
    <Carousel
      showThumbs={false}
      showStatus={false}
      autoPlay={true}
      infiniteLoop={true}
      swipeable={false}
      animationHandler="fade"
      dynamicHeight={true}
    >
      {carouselData.map((data, index) => {
        return (
          <div key={index} className="relative">
            <img
              className="object-cover h-80 md:h-96 w-full"
              src={data.image}
              alt={index}
            />
            <div className="absolute z-10 left-10 right-10 bottom-10 md:left-20 md:right-0 md:bottom-10 bg-sct-primary/75 md:w-1/4 rounded-md">
              <div className="p-2">
                <FaQuoteLeft className="text-sct-white" />
                <p className="text-sct-white italic ml-3 inline-block">
                  {data.legendQuote}
                </p>
                <FaQuoteRight className="absolute right-2 text-sct-white" />
                <p className="text-sct-white font-semibold italic mt-4">
                  {data.legendBold}
                </p>
              </div>
            </div>
          </div>
        );
      })}
    </Carousel>
  );
};

export default MainCarousel;
