import React from 'react';

const ScrumSolution = () => {
  return (
    <section className="relative my-16">
      <div className="container flex flex-col-reverse lg:flex-row items-center gap-12">
        {/* Content */}
        <div className="flex flex-1 flex-col items-center lg:items-start">
          <h2 className="text-sct-primary text-xl md:text-2xl lg:text-3xl text-center lg:text-left mb-6 font-semibold">
            Our Solution
          </h2>
          <p className="text-2xl font-bold mb-2">
            iScrum AI Delivers Real Solutions Through Real-Time Meeting Management
          </p>
          <ol className="list-disc ml-4 text-sct-grey">
            <br />
            <li className="ml-3 mb-3">
              <b>Manage Your Hybrid Workforce with Ease.</b> As hybrid work models continue to rely on a combination of remote and in-person employees,
              effective meeting management is more important than ever before. Improve long-term
              productivity by bringing everyone together with iScrum.
            </li>
            <li className="ml-3 mb-3">
              <b>Save Time With Automatic Meeting Summaries.</b> Extract the essential meeting details with succinct and relevant summaries. Our intelligent
              conversation assistant automatically summarizes meetings and creates notes from voice actions.
              in real-time.
            </li>
            <li className="ml-3 mb-3">
              <b>Keep Every Agenda Discussion On Track.</b> Don’t waste time with off-topic discussions that prevent progress toward achieving your
              meeting objectives. iScrum AI instantly detects meeting inefficiencies, and alerts you when
              meetings are running overtime, or are derailed by off-topic conversations.
            </li>
            <li className="ml-3 mb-3">
              <b>Promote Team Synergy.</b> By keeping employees accountable for their project contributions, iScrum enables team pride in
              shared outcomes and successful results. Manage cross-functional collaboration of time-
              sensitive projects to ensure expectations are met and deliverables are seamlessly executed.
            </li>
            <li className="ml-3 mb-3">
              <b>Optimize Meeting Productivity.</b> iScrum AI utilizes robust AI algorithms to optimize total meeting time and ensure the success of
              every meeting you conduct.
            </li>
          </ol>
          {/*<p className="text-2xl font-bold mb-2">
            iScrum helps to manage your meetings effectively.
          </p>
          <ol className="list-disc ml-4 text-sct-grey">
            <br />
            <li className="ml-3 mb-3">
              iScrum is an AI-powered meeting solution that helps you make your
              remote and in-person meetins more efficent and productive.
            </li>
            <li className="ml-3 mb-3">
              This intelligent conversation assistant summarizes your meetings
              and creates notes from voice actions in real-time.
            </li>
            <li className="ml-3 mb-3">
              iSrcum also detects and alerts you on meeting inefficiencies like
              overtime and off-topic conversations.
            </li>
            <li className="ml-3 mb-3">
              It further utlizes robust AI algorithms to optimze total meeting
              time ands ensures the success of every meeting you conduct.
            </li>
          </ol>*/}
        </div>
        {/* Image */}
        <div className="flex justify-center flex-1 z-10">
          <img
            className="w-5/6 h-5/6 sm:w-3/4 sm:h-3/4 md:w-full md:h-full"
            src={require('../../../assets/images/clients/scrumsolution_new.png')}
          />
        </div>
      </div>
    </section>
  );
};

export default ScrumSolution;
