import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./style.css";
import { useNavigate} from "react-router-dom";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    paritialVisibilityGutter: 60,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    paritialVisibilityGutter: 50,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    paritialVisibilityGutter: 30,
  },
};
const testimonialData = [
  {
    imageUrl: require("../../../assets/images/clients/vaco.png"),
    name: "Vaco",
    companyName: "Josh Grummel — Associate, Consulting Services",
    review: `Source code Technologies is a fantastic source for top-notch candidates. Reqs open, close quickly and they are quick to the punch. Not only are their consultants excellent Technology folks but also good communicators, they've proven to be diligent employees. I highly recommend them for talent buyers looking for IT professionals with cutting-edge skills`,
  },
  {
    imageUrl: require("../../../assets/images/clients/eliassen.jpg"),
    name: "Eliassen Group",
    companyName: "Matt Sidebottom — Senior Technical Recruiter",
    review: ` Compared to other firms I’ve worked with, Source Code Technologies has done a better job at engaging us early in the process. They fully respect our process and guidelines, too.`,
  },
  {
    imageUrl: require("../../../assets/images/clients/kforce1.png"),
    name: "KForce",
    companyName: "— Strategic Delivery Manager",
    review: `I was impressed by Source Code's ability to generate trust with partners and their fluidity to solve any problems.`,
  },
  {
    imageUrl: require("../../../assets/images/clients/pyramid1.png"),
    name: "Pyramid Consulting, Inc",
    companyName: "— Senior Executive Resourcing",
    review: `Source Code Technologies is very professional in the way they communicate and act.`,
  },
  {
    imageUrl: require("../../../assets/images/clients/resilient-logo.png"),
    name: "ResiliEnt Business Solutions",
    companyName: "Stephanie Keaton — Director Resource Management",
    review: `ResiliEnt Business Solutions has worked with Source Code Technologies for just over a year and could not be more pleased with the level of professionalism and responsiveness to any inquiry we have. Kumar and his team will not disappoint if you are looking for qualified consultants for information technology roles.`,
  },
  {
    imageUrl: require("../../../assets/images/clients/datrix.jpg"),
    name: "Datrix AI",
    companyName: "— Director,  AI/ML Consulting firm",
    review: `It's truly impressive how fast Source Code Technologies adapted their methodology, architecture and infrastructure for our exact needs in AI Implementations.`,
  },
  {
    imageUrl:
      "https://images.unsplash.com/photo-1550133730-695473e544be?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60",
    name: "Techno Techie Consulting",
    companyName: "Bhagya — CEO, Data & Analytics",
    review: `We began working with Source Code Technologies because we were facing a lot of challenges within our organisation. We were not organized, we were not effectively using our existing tools and we were not delivering projects to the clients on time.Overall Source code has a great resources of consultants and I would recommend it for any new Technology implementations.`,
  }
];

const images = [
  "https://images.unsplash.com/photo-1549989476-69a92fa57c36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60",
  "https://images.unsplash.com/photo-1549396535-c11d5c55b9df?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60",
  "https://images.unsplash.com/photo-1550133730-695473e544be?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60",
  "https://images.unsplash.com/photo-1550167164-1b67c2be3973?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60",
  "https://images.unsplash.com/photo-1550338861-b7cfeaf8ffd8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60",
  "https://images.unsplash.com/photo-1550223640-23097fc71cb2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60",
  "https://images.unsplash.com/photo-1550353175-a3611868086b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60",
  "https://images.unsplash.com/photo-1550330039-a54e15ed9d33?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60",
  "https://images.unsplash.com/photo-1549737328-8b9f3252b927?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60",
  "https://images.unsplash.com/photo-1549833284-6a7df91c1f65?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60",
  "https://images.unsplash.com/photo-1549985908-597a09ef0a7c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60",
  "https://images.unsplash.com/photo-1550064824-8f993041ffd3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60",
];

const TestimonialSlider = ({ deviceType }) => {
  const navigate = useNavigate();
  return (
    <section className="bg-white py-20">
      <div className="sm:w-3/4 mx-auto px-2">
        <h1 className="text-3xl text-center text-sct-primary font-semibold">
          What our Clients say
        </h1>
      </div>

      <div className="container mt-16">
        <Carousel
          ssr
          partialVisbile
          deviceType={deviceType}
          itemClass="image-item"
          responsive={responsive}
        >
          {testimonialData.map((data, index) => {
            return (
              <div
                className="bg-white p-3 shadow-md mr-4 cursor-pointer transition-all md:scale-95 md:hover:scale-100 h-full"
                key={index}
              >
                <div className="image overflow-hidden">
                  <img
                    className=" sm:h-44 w-full mx-auto"
                    src={data.imageUrl}
                    alt=""
                  />
                </div>
                <div className="mb-6">
                  <h1 className="text-gray-900 font-bold text-xl leading-8 my-1">
                    {data.name}
                  </h1>
                  <h3 className="text-gray-600 font-lg text-semibold leading-6">
                    {data.companyName}
                  </h3>
                  <br />
                  <p className="text-sm text-gray-500 hover:text-gray-600 leading-6 text-limit">
                    {data.review}
                  </p>
                </div>
                <div
                  className="absolute bottom-2 right-4"
                  onClick={() => navigate("/testimonials/")}
                >
                  <p className="text-sm text-gray-500 hover:text-gray-600 leading-6">
                    Read More
                  </p>
                </div>
              </div>
            );
          })}
        </Carousel>
      </div>
    </section>
  );
};

export default TestimonialSlider;
