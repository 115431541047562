import React from 'react';

const ApplicationServicesHome = () => {
  return (
    <section className="relative my-16">
      <div className="container flex flex-col-reverse lg:flex-row items-center gap-12">
        {/* Content */}
        <div className="flex flex-1 flex-col items-center lg:items-start">
          <h2 className="text-sct-primary text-xl md:text-2xl lg:text-3xl text-center lg:text-left mb-6 font-semibold">
            Agile Transformation
          </h2>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            Agile Transformation Services helps companies achieve greater agility to accelerate business change. We introduce Lean and Agile principles and practices to continuously transform ways of working across the enterprise.
          </p>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            From the development of new applications, through modernization, management and maintenance,
            we cover all stages of the application lifecycle.
          </p>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            Our seamless, end-to-end approach helps organzations provide high-quality software delivery and improve responsiveness to business and customer demands.
          </p>
        </div>
        {/* Image */}
        <div className="flex justify-center flex-1 z-10">
          <img
            className="w-5/6 h-5/6 sm:w-3/4 sm:h-3/4 md:w-full md:h-full"
            src={require('../../../assets/images/clients/endtoend.png')}
          />
        </div>
      </div>
      <div className="container flex flex-col-reverse lg:flex-row-reverse items-center gap-12">
        {/* Content */}
        <div className="flex flex-1 flex-col items-center lg:items-start">
          <h2 className="text-sct-primary text-xl md:text-2xl lg:text-3xl text-center lg:text-left mb-6 font-semibold">
            Application Management
          </h2>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            Our management services deliver operational efficiencies that lower costs and implement intelligent business processes by integrating data, AI and advanced automation.
          </p>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            The ultimate goal of Application Management is to implement efficient, reliable, and cost-effective code that enables an enterprise to meet its business objectives by ensuring that the required capabilities – both management and technical – are in place, and to further ensure that any technical issues are rapidly diagnosed and resolved.
          </p>
        </div>
        {/* Image */}
        <div className="flex justify-center flex-1 z-10">
          <img
            className="w-5/6 h-5/6 sm:w-3/4 sm:h-3/4 md:w-full md:h-full"
            src={require('../../../assets/images/clients/appmanagement.png')}
          />
        </div>
      </div>

      <div className="container flex flex-col-reverse lg:flex-row items-center gap-12">
        {/* Content */}
        <div className="flex flex-1 flex-col items-center lg:items-start">
          <h2 className="text-sct-primary text-xl md:text-2xl lg:text-3xl text-center lg:text-left mb-6 font-semibold">
            Application Modernization
          </h2>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            Transforming your legacy applications to become more agile and efficient through the power of New IT.
          </p>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            We make legacy systems more agile and efficient through the use of intelligent automation, industrialized assets, specialized skills and global delivery capabilities.
          </p>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            Migrates your applications to a standards-based environment while minimizing disruption to the business – using rehosting,
            refactoring, digital decoupling and containerization techniques.
          </p>
        </div>
        {/* Image */}
        <div className="flex justify-center flex-1 z-10">
          <img
            className="w-5/6 h-5/6 sm:w-3/4 sm:h-3/4 md:w-full md:h-full"
            src={require('../../../assets/images/clients/modernization.png')}
          />
        </div>
      </div>

      <div className="container flex flex-col-reverse lg:flex-row-reverse items-center gap-12">
        {/* Content */}
        <div className="flex flex-1 flex-col items-center lg:items-start">
          <h2 className="text-sct-primary text-xl md:text-2xl lg:text-3xl text-center lg:text-left mb-6 font-semibold">
            Application Architecture
          </h2>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            We help strategizing the future IT by assessing the existing, defining the enterprise technology vision and orchestrating the transformation.
          </p>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            We architect and create Cloud-native and traditional applications, systems and platforms to enable business differentiation and innovation.
          </p>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            We ensure Cloud, on-premise and IoT systems meet their expected service levels, supporting all phases from design, build, test to live operations.
          </p>
        </div>
        {/* Image */}
        <div className="flex justify-center flex-1 z-10">
          <img
            className="w-5/6 h-5/6 sm:w-3/4 sm:h-3/4 md:w-full md:h-full"
            src={require('../../../assets/images/clients/architect.png')}
          />
        </div>
      </div>
    </section>
  );
};

export default ApplicationServicesHome;
