import React from 'react';

const TechnologyConsultingHome = () => {
  return (
    <section className="relative mt-8 mb-8">
      <div className="container flex flex-col-reverse lg:flex-row items-center gap-12">
        {/* Content */}
        <div className="flex flex-1 flex-col items-center lg:items-start">
          <h2 className="text-sct-primary text-xl md:text-2xl lg:text-3xl text-center lg:text-left mb-6 font-semibold">
            Blockchain
          </h2>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            The future of business will rest on the foundation of the internet of value. Markets around the world are witnessing
            the emergence of a new stakeholder “prosumer” in many industries as the lines between producers and consumers of goods
            and services are blurred. A new asset class – ‘data’ is fundamentally transforming how value is created, captured and distributed.
          </p>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            With the dynamic economic and geo-political circumstances worldwide, the overarching theme that threads the potential values
            derived from blockchain is “Sustainability”. Sustainability for the environment, sustainability for the people (producers and consumers)
            and sustainability for the businesses. When applied rightfully, blockchain can create a fairer economy and stable markets and governments.
            Blockchain is not to be looked at as a rip-and-replace technology but a foundational force integrated with new age digital technologies such as AI, ML, IoT.
          </p>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            We help clients unlock the true business value of blockchain in their digital journey by accelerating ecosystem-wide adoption,
            enabling enterprise integration and innovating with next-gen technologies.
          </p>
        </div>
        {/* Image */}
        <div className="flex justify-center flex-1 z-10">
          <img
            className="w-5/6 h-5/6 sm:w-3/4 sm:h-3/4 md:w-full md:h-full"
            src={require('../../../assets/images/clients/blockchain.png')}
          />
        </div>
      </div>
      <div className="container flex flex-col-reverse lg:flex-row-reverse items-center gap-12">
        {/* Content */}
        <div className="flex flex-1 flex-col items-center lg:items-start">
          <h2 className="text-sct-primary text-xl md:text-2xl lg:text-3xl text-center lg:text-left mb-6 font-semibold">
            Quantum Computing
          </h2>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            Quantum technology is rapidly advancing. Forward-thinking companies are already experimenting with cloud-based and hybrid quantum computing,
            positioning themselves to capitalize on the unique and powerful innovations that quantum will unleash.
          </p>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            This is bringing transformation potential to multiple industries, making it possible to discover new remedies in life sciences,
            improve wealth management scenarios in financial services, reroute supply chains in real time and more. However, a haphazard
            enterprise approach to quantum—such as researching only a single use case or proceeding without a cohesive strategy—yields
            exactly what you would expect: innovative sparks with no lasting business value.
          </p>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            The quantum-fueled future will arrive with a sudden and rapid shift. Prepare your business now and take advantage of the growth potential
            the technology will provide as it continues to mature. Technology that has extreme disruption potential has historically proven to change
            the competitive landscape nearly overnight. Fast-track your journey with a cohesive strategy—including relevant use cases,
            an innovation roadmap, hardware/software partnerships and skilled talent—to gain an advantageous position for the quantum future.
          </p>
        </div>
        {/* Image */}
        <div className="flex justify-center flex-1 z-10">
          <img
            className="w-5/6 h-5/6 sm:w-3/4 sm:h-3/4 md:w-full md:h-full"
            src={require('../../../assets/images/clients/quantum.png')}
          />
        </div>
      </div>
    </section>
  );
};

export default TechnologyConsultingHome;
