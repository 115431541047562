import React from 'react';

const DatrixMachineLearningSection = () => {
  return (
    <section className="relative my-16">
      <div className="container flex flex-col-reverse lg:flex-row items-center gap-12">
        {/* Content */}
        <div className="flex flex-1 flex-col items-center lg:items-start">
          <h2 className="text-sct-primary text-xl md:text-2xl lg:text-3xl text-center lg:text-left mb-6 font-semibold">
            Machine Learning and Programming Workshops
          </h2>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            We provide 3 to 5 day workshops on following topics
            <ol className="list-disc ml-4 text-sct-grey">
              <br />
              <li className="ml-3 mb-3">ROS - Robotics operating system.</li>
              <li className="ml-3 mb-3">
                Deep Learning in Computer Vision - beginner and intermediate
                audience.
              </li>
              <li className="ml-3 mb-3">
                Deep Learning in Natural Language Processing - beginner and
                intermediate audience.
              </li>
              <li className="ml-3 mb-3">
                Python - beginner, intermediate and Advanced programming.
              </li>
              <li className="ml-3 mb-3">
                C++ - beginner and intermediate programming.
              </li>
              <li className="ml-3 mb-3">Git and Github workflow.</li>
            </ol>
          </p>
        </div>
        {/* Image */}
        <div className="flex justify-center flex-1 z-10">
          <img
            className="w-5/6 h-5/6 sm:w-3/4 sm:h-3/4 md:w-full md:h-full"
            src={require('../../../assets/images/clients/datrix_ml.png')}
          />
        </div>
      </div>
    </section>
  );
};

export default DatrixMachineLearningSection;
