import React from 'react';
import {AiFillCheckCircle} from "react-icons/ai";
import Ecommerce from "../../assets/images/clients/ecommerce1.svg";
import Checkout from "../../assets/images/clients/ecommerce2.svg";
import Payments from "../../assets/images/clients/payment-gateway.png";
import Wishlist from "../../assets/images/clients/ecommerce5.svg";
import Delivery from "../../assets/images/clients/ecommerce4.svg";
import Secure from "../../assets/images/clients/ecommerce6.svg";
import Layout from "../../assets/images/clients/ecommerce7.svg";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';


const Shopzilla = () => {
    const carouselData = [
        {
            image: require('../../assets/images/clients/shopzilla1.png'),
        },
        {
            image: require('../../assets/images/clients/shopzilla2.png')
        },
        {
            image: require('../../assets/images/clients/shopzilla3.png')
        },
    ];
  return (
      <div>

          <section className="container flex items-center justify-center flex-col">
              <h2 className="text-5xl text-center capitalize font-semibold mt-20">
                  <span className="text-sct-primary">E-Commerce Suite.</span>{' '}
                  Product Listing, Payments & Shipments
              </h2>

              <p className="text-2xl my-12">
                  The all-in-one commerce platform. Endlessly flexible, extensible, and scalable
              </p>

              <Carousel
                  showThumbs={false}
                  showStatus={false}
                  autoPlay={true}
                  infiniteLoop={true}
                  swipeable={false}
                  animationHandler="fade"
                  dynamicHeight={true}
              >
                  {carouselData.map((data, index) => {
                      return (
                          <div key={index} className="relative">
                              <img
                                  className="object-cover h-80 md:h-96 w-full"
                                  src={data.image}
                                  alt={index}
                              />
                          </div>
                      );
                  })}
              </Carousel>
          </section>

          <section className="container flex items-center gap-4 py-10 flex-col lg:flex-row">
              <div className="flex-1">
                  <h3 className="text-4xl font-bold text-center lg:text-left">
                      Simplifying e-commerce services between consumers and sellers
                  </h3>

                  <ul className="my-8">
                      <li className="flex items-start my-2">
                          <div>
                              <AiFillCheckCircle
                                  size={20}
                                  className="text-sct-primary mr-4 mt-1"
                              />
                          </div>
                          <p className="text-lg">
                              The best ecommerce platform overall - support a wide range of businesses
                          </p>
                      </li>
                      <li className="flex items-start my-2">
                          <div>
                              <AiFillCheckCircle
                                  size={20}
                                  className="text-sct-primary mr-4 mt-1"
                              />
                          </div>
                          <p className="text-lg">
                              A complete solution curated specially for e-commerce
                          </p>
                      </li>
                      <li className="flex items-start my-2">
                          <div>
                              <AiFillCheckCircle
                                  size={20}
                                  className="text-sct-primary mr-4 mt-1"
                              />
                          </div>
                          <p className="text-lg">
                              Fast, secure, scalable and easy to use
                          </p>
                      </li>
                      <li className="flex items-start my-2">
                          <div>
                              <AiFillCheckCircle
                                  size={20}
                                  className="text-sct-primary mr-4 mt-1"
                              />
                          </div>
                          <p className="text-lg">
                              Best for fast-growing businesses,enterprise and retail businesses
                          </p>
                      </li>
                  </ul>
              </div>
              <div className="flex-1">
                  <img src={Ecommerce} />
              </div>
          </section>

          <div className="bg-sct-white">
              <section className="container py-12">
                  <h3 className="text-center text-3xl font-semibold">
                      Features
                  </h3>

                  <div className="grid grid-cols-1 md:grid-cols-2 gap-20 my-16">
                      <div className="rounded-lg overflow-hidden bg-white py-8 px-4">
                          <div className=" py-4 px-2 flex items-center justify-center">
                              <img src={Wishlist} />
                          </div>

                          <div className="flex flex-col items-center justify-center">
                              <p className="my-4 text-xl text-gray-600">Responsive Layout</p>
                              <h3 className="mb-6 text-3xl font-bold"> User Friendly Interface</h3>
                              <p className="text-2xl font-extralight text-gray-500 text-center">
                                  Elegant and Tailored User interface
                              </p>
                          </div>
                      </div>
                      <div className="rounded-lg overflow-hidden bg-white py-8 px-4">
                          <div className=" py-4 px-2 flex items-center justify-center">
                              <img src={Secure} />
                          </div>

                          <div className="flex flex-col items-center justify-center">
                              <p className="my-4 text-xl text-gray-600">Secure Payment Infrastructure</p>
                              <h3 className="mb-6 text-3xl font-bold">Payment Gateways</h3>
                              <p className="text-2xl font-extralight text-gray-500 text-center">
                                  Checkout made easy with all secured payment gateways includes Paypal, Stripe, etc.
                              </p>
                          </div>
                      </div>
                      <div className="rounded-lg overflow-hidden bg-white py-8 px-4">
                          <div className=" py-4 px-2 flex items-center justify-center">
                              <img src={Layout} />
                          </div>

                          <div className="flex flex-col items-center justify-center">
                              <p className="my-4 text-xl text-gray-600">Customisable</p>
                              <h3 className="mb-6 text-3xl font-bold">Easy Maintenance</h3>
                              <p className="text-2xl font-extralight text-gray-500 text-center">
                                  Product listing made easy.
                              </p>
                          </div>
                      </div>
                      <div className="rounded-lg overflow-hidden bg-white py-8 px-4">
                          <div className=" py-4 px-2 flex items-center justify-center">
                              <img src={Delivery} />
                          </div>

                          <div className="flex flex-col items-center justify-center">
                              <p className="my-4 text-xl text-gray-600">Integration with Delivery Partners</p>
                              <h3 className="mb-6 text-3xl font-bold">Order Delivery</h3>
                              <p className="text-2xl font-extralight text-gray-500 text-center">
                                  Efficient order shipping and delivery integration.
                              </p>
                          </div>
                      </div>
                  </div>
              </section>
          </div>

          <section className="container flex items-center gap-4 py-10 flex-col lg:flex-row-reverse">
              <div className="flex-1">
                  <h3 className="text-4xl font-bold text-center lg:text-left">
                      Secure payment integration with all PCI Complaint payment gateway providers
                  </h3>

                  <ul className="my-8">
                      <li className="flex items-start my-2">
                          <div>
                              <AiFillCheckCircle
                                  size={20}
                                  className="text-sct-primary mr-4 mt-1"
                              />
                          </div>
                          <p className="text-lg">
                              Paypal Integration with flexible payment options.
                          </p>
                      </li>
                      <li className="flex items-start my-2">
                          <div>
                              <AiFillCheckCircle
                                  size={20}
                                  className="text-sct-primary mr-4 mt-1"
                              />
                          </div>
                          <p className="text-lg">
                              Stripe - Unified Payment platform. A fully integrated suite of payments products.
                          </p>
                      </li>
                      <li className="flex items-start my-2">
                          <div>
                              <AiFillCheckCircle
                                  size={20}
                                  className="text-sct-primary mr-4 mt-1"
                              />
                          </div>
                          <p className="text-lg">
                              Visa & Mastercard integration.
                          </p>
                      </li>
                      <li className="flex items-start my-2">
                          <div>
                              <AiFillCheckCircle
                                  size={20}
                                  className="text-sct-primary mr-4 mt-1"
                              />
                          </div>
                          <p className="text-lg">
                              Apple Pay Integration.
                          </p>
                      </li>
                  </ul>
              </div>
              <div className="flex-1">
                  <img src={Payments} />
              </div>
          </section>
      </div>
  );
};

export default Shopzilla;
