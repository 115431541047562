import React from 'react';
import {AiFillCheckCircle} from "react-icons/ai";
import Invest from "../../assets/images/clients/investing1.png";
import Investify1 from "../../assets/images/clients/investify.png";
import Consult from "../../assets/images/clients/consulting.png";
import Business from "../../assets/images/clients/business1.png";
import Delivery from "../../assets/images/clients/ecommerce4.svg";
import Finance from "../../assets/images/clients/finance.png";
import Statistics from "../../assets/images/clients/statistics.png";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';


const Investify = () => {
    const carouselData = [
        {
            image: require('../../assets/images/clients/investify1.png'),
        },
        {
            image: require('../../assets/images/clients/investify2.png')
        },
        {
            image: require('../../assets/images/clients/investify3.png')
        },
    ];
  return (
      <div>

          <section className="container flex items-center justify-center flex-col">
              <h2 className="text-5xl text-center capitalize font-semibold mt-20">
                  <span className="text-sct-primary">Investment Platform.</span>{' '}
                  Investment Services & Financial Management
              </h2>

              <p className="text-2xl my-12">
                  The all-in-one investment platform. Endlessly flexible, extensible, and scalable.
              </p>

              <Carousel
                  showThumbs={false}
                  showStatus={false}
                  autoPlay={true}
                  infiniteLoop={true}
                  swipeable={false}
                  animationHandler="fade"
                  dynamicHeight={true}
              >
                  {carouselData.map((data, index) => {
                      return (
                          <div key={index} className="relative">
                              <img
                                  className="object-cover h-80 md:h-96 w-full"
                                  src={data.image}
                                  alt={index}
                              />
                          </div>
                      );
                  })}
              </Carousel>
          </section>

          <section className="container flex items-center gap-4 py-10 flex-col lg:flex-row">
              <div className="flex-1">
                  <h3 className="text-4xl font-bold text-center lg:text-left">
                      Excellent Investments and Financial Services
                  </h3>

                  <ul className="my-8">
                      <li className="flex items-start my-2">
                          <div>
                              <AiFillCheckCircle
                                  size={20}
                                  className="text-sct-primary mr-4 mt-1"
                              />
                          </div>
                          <p className="text-lg">
                              The best investment platform overall - support diversified portfolios.
                          </p>
                      </li>
                      <li className="flex items-start my-2">
                          <div>
                              <AiFillCheckCircle
                                  size={20}
                                  className="text-sct-primary mr-4 mt-1"
                              />
                          </div>
                          <p className="text-lg">
                              A complete solution curated specially for investment.
                          </p>
                      </li>
                      <li className="flex items-start my-2">
                          <div>
                              <AiFillCheckCircle
                                  size={20}
                                  className="text-sct-primary mr-4 mt-1"
                              />
                          </div>
                          <p className="text-lg">
                              Fast, secure, scalable and easy to use.
                          </p>
                      </li>
                      <li className="flex items-start my-2">
                          <div>
                              <AiFillCheckCircle
                                  size={20}
                                  className="text-sct-primary mr-4 mt-1"
                              />
                          </div>
                          <p className="text-lg">
                              Tailored portfolio management.
                          </p>
                      </li>
                  </ul>
              </div>
              <div className="flex-1">
                  <img src={Invest} />
              </div>
          </section>

          <div className="bg-sct-white">
              <section className="container py-12">
                  <h3 className="text-center text-3xl font-semibold">
                      Features
                  </h3>

                  <div className="grid grid-cols-1 md:grid-cols-2 gap-20 my-16">
                      <div className="rounded-lg overflow-hidden bg-white py-8 px-4">
                          <div className=" py-4 px-2 flex items-center justify-center">
                              <img src={Consult} />
                          </div>

                          <div className="flex flex-col items-center justify-center">
                              <p className="my-4 text-xl text-gray-600">Professional Service</p>
                              <h3 className="mb-6 text-3xl font-bold"> One-on-One advice </h3>
                              <p className="text-2xl font-extralight text-gray-500 text-center">
                                  Create a financial plan and investment strategy tailored to your goals.
                              </p>
                          </div>
                      </div>
                      <div className="rounded-lg overflow-hidden bg-white py-8 px-4">
                          <div className=" py-4 px-2 flex items-center justify-center">
                              <img src={Business} />
                          </div>

                          <div className="flex flex-col items-center justify-center">
                              <p className="my-4 text-xl text-gray-600">Tailored Service</p>
                              <h3 className="mb-6 text-3xl font-bold">Portfolio Management</h3>
                              <p className="text-2xl font-extralight text-gray-500 text-center">
                                  Build a diversified portfolio.
                              </p>
                          </div>
                      </div>
                      <div className="rounded-lg overflow-hidden bg-white py-8 px-4">
                          <div className=" py-4 px-2 flex items-center justify-center">
                              <img src={Finance} />
                          </div>

                          <div className="flex flex-col items-center justify-center">
                              <p className="my-4 text-xl text-gray-600">Get unlimited guidance</p>
                              <h3 className="mb-6 text-3xl font-bold">Financial Guidance</h3>
                              <p className="text-2xl font-extralight text-gray-500 text-center">
                                  Get guidance that's personalized just for you.
                              </p>
                          </div>
                      </div>
                      <div className="rounded-lg overflow-hidden bg-white py-8 px-4">
                          <div className=" py-4 px-2 flex items-center justify-center">
                              <img src={Statistics} />
                          </div>

                          <div className="flex flex-col items-center justify-center">
                              <p className="my-4 text-xl text-gray-600">Invest on right shares</p>
                              <h3 className="mb-6 text-3xl font-bold">Brokerage & Trading</h3>
                              <p className="text-2xl font-extralight text-gray-500 text-center">
                                  Bring your trading to next level.
                              </p>
                          </div>
                      </div>
                  </div>
              </section>
          </div>

          <section className="container flex items-center gap-4 py-10 flex-col lg:flex-row-reverse">
              <div className="flex-1">
                  <h3 className="text-4xl font-bold text-center lg:text-left">
                      Secure and trusted investment platform
                  </h3>

                  <ul className="my-8">
                      <li className="flex items-start my-2">
                          <div>
                              <AiFillCheckCircle
                                  size={20}
                                  className="text-sct-primary mr-4 mt-1"
                              />
                          </div>
                          <p className="text-lg">
                              Create a personalized plan and investment strategy with guidance on your terms.
                          </p>
                      </li>
                      <li className="flex items-start my-2">
                          <div>
                              <AiFillCheckCircle
                                  size={20}
                                  className="text-sct-primary mr-4 mt-1"
                              />
                          </div>
                          <p className="text-lg">
                              Easily trade and manage investments with Investify.
                          </p>
                      </li>
                      <li className="flex items-start my-2">
                          <div>
                              <AiFillCheckCircle
                                  size={20}
                                  className="text-sct-primary mr-4 mt-1"
                              />
                          </div>
                          <p className="text-lg">
                              Access a team of professionals who share your passion for trading.
                          </p>
                      </li>
                      <li className="flex items-start my-2">
                          <div>
                              <AiFillCheckCircle
                                  size={20}
                                  className="text-sct-primary mr-4 mt-1"
                              />
                          </div>
                          <p className="text-lg">
                              Trade smarter with powerful and intuitive trading <platforms className=""></platforms>
                          </p>
                      </li>
                  </ul>
              </div>
              <div className="flex-1">
                  <img src={Investify1} />
              </div>
          </section>
      </div>
  );
};

export default Investify;
