import React, {useRef, useState} from 'react';
import Banner from '../../components/UI/Banner';
import {FaHome, FaEnvelope, FaPhoneAlt, FaCodeBranch} from 'react-icons/fa';
import emailjs from '@emailjs/browser';
import {Formik} from 'formik';
import ModelBox from '../../components/shared/ModelBox';
import LoadingSpinner from '../../components/shared/LoadingSpinner';
import {useNavigate} from 'react-router-dom';

const ContactUs = () => {
    const navigate = useNavigate();
    const contactForm = useRef(null);
    const initialValues = {
        firstName: '',
        lastName: '',
        phone: '',
        message: '',
    };
    const [hidden, setHidden] = useState(true);
    const [notLoading, setNotLoading] = useState(true);

    const validate = (values) => {
        let errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

        if (!values.firstName) {
            errors.firstName = 'This field is required';
        }
        if (!values.lastName) {
            errors.lastName = 'This field is required';
        }
        if (!values.phone) {
            errors.phone = 'This field is required';
        }
        if (!values.message) {
            errors.message = 'This field is required';
        }

        return errors;
    };

    const submitForm = (values, {resetForm}) => {
        console.log(values);
        setNotLoading(false);
        emailjs
            .sendForm(
                'service_c9vda1l',
                'template_4syk098',
                contactForm.current,
                '84YeMiWPn7TqB09XQ'
            )
            .then((data) => {
                console.log(data);
                setNotLoading(true);
                setHidden(false);
                resetForm();
            });
    };

    const onOkay = () => {
        setHidden(true);
        navigate('/');
    };

    return (
        <div className="scroll-smooth">
            <LoadingSpinner hidden={notLoading}/>
            <ModelBox
                hidden={hidden}
                title="Inquiry Recieved!"
                subTitle="Thanks for contacting us. Our team will contact you soon.."
                onOkay={onOkay}
            />
            <Banner
                imgUrl="https://images.unsplash.com/photo-1542435503-956c469947f6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80"
                heading="Contact Us"
            />
            <div className="my-16">
                <div className="container">
                    <div className="flex justify-center flex-1 z-10">
                        <img
                            className="w-3/6 h-4/12 "
                            src={require('../../assets/images/clients/getInTouch.png')}
                        />
                    </div>
                    <div className="flex flex-1 flex-col md:flex-row md:text-left">
                        <div className="flex-1 p-5">
                            <h1 className="text-4xl font-bold tracking-tight text-sct-primary">
                                Get in touch
                            </h1>
                            <div className="mt-6">

                                <h6 className="uppercase font-semibold mb-4 flex justify-center md:justify-start">
                                    Headquarters
                                </h6>
                                <p className="flex items-center md:justify-start mb-4">
                                    {/*<FaHome className="text-2xl mr-4"/>*/}
                                    26000 Towne Center Dr N, Suite # 210,
                                    <br/>Lake Forest, CA 92610
                                </p>
                                <h6 className="uppercase font-semibold mb-4 flex justify-center md:justify-start">
                                    Austin Office
                                </h6>
                                <p className="flex items-center justify-center md:justify-start mb-4">
                                    {/*<FaCodeBranch className="text-xl mr-4"/>*/}
                                    9900 Spectrum Dr,<br/> Austin, TX 78717.
                                </p>
                                <h6 className="uppercase font-semibold mb-4 flex justify-center md:justify-start">
                                    Colorado Office
                                </h6>
                                <p className="flex items-center justify-center md:justify-start mb-4">
                                    {/*<FaCodeBranch className="text-xl mr-4"/>*/}
                                    121 S. Tejon Street, Suite 201,<br/>Colorado Springs, CO 80903.
                                </p>
                                <h6 className="uppercase font-semibold mb-4 flex justify-center md:justify-start">
                                    Michigan Office
                                </h6>
                                <p className="flex items-center justify-center md:justify-start mb-4">
                                {/*<FaCodeBranch className="text-xl mr-4"/>*/}
                                336 W. First Street, Suite 113,<br/>Flint, MI 48502.
                                </p>
                                <h6 className="uppercase font-semibold mb-4 flex justify-center md:justify-start">
                                    Contact Info
                                </h6>
                                <p className="flex items-center  md:justify-start mb-4">
                                    <FaEnvelope className="text-2xl mr-4"/>
                                    admin@sourcecodetecno.com
                                </p>
                                <p className="flex items-center md:justify-start mb-4">
                                    <FaPhoneAlt className="text-2xl mr-4"/>
                                    949.344.4069 <br/> 949.822.7011
                                </p>
                            </div>
                        </div>
                        <div className="flex-1 p-5">
                            <Formik
                                initialValues={initialValues}
                                validate={validate}
                                onSubmit={submitForm}
                            >
                                {(formik) => {
                                    const {
                                        values,
                                        handleChange,
                                        handleSubmit,
                                        errors,
                                        touched,
                                        handleBlur,
                                        isValid,
                                        dirty,
                                    } = formik;
                                    return (
                                        <form onSubmit={handleSubmit} ref={contactForm}>
                                            <div className="flex flex-wrap -mx-3 mb-6">
                                                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                                    <label
                                                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 after:content-['*'] after:ml-0.5 after:text-red-500"
                                                        htmlFor="grid-first-name"
                                                    >
                                                        First Name
                                                    </label>
                                                    <input
                                                        className={`appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 ${
                                                            errors.firstName && touched.firstName
                                                                ? 'border-red-500'
                                                                : 'border-gray-200'
                                                        }`}
                                                        id="grid-first-name"
                                                        type="text"
                                                        name="firstName"
                                                        value={values.firstName}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                    {errors.firstName && touched.firstName && (
                                                        <p className="text-red-500 text-xs italic">
                                                            {errors.firstName}
                                                        </p>
                                                    )}
                                                </div>
                                                <div className="w-full md:w-1/2 px-3">
                                                    <label
                                                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 after:content-['*'] after:ml-0.5 after:text-red-500"
                                                        htmlFor="grid-last-name"
                                                    >
                                                        Last Name
                                                    </label>
                                                    <input
                                                        className={`appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 ${
                                                            errors.lastName && touched.lastName
                                                                ? 'border-red-500'
                                                                : 'border-gray-200'
                                                        }`}
                                                        id="grid-last-name"
                                                        type="text"
                                                        name="lastName"
                                                        value={values.lastName}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                    {errors.lastName && touched.lastName && (
                                                        <p className="text-red-500 text-xs italic">
                                                            {errors.lastName}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="flex flex-wrap -mx-3 mb-6">
                                                <div className="w-full px-3">
                                                    <label
                                                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 after:content-['*'] after:ml-0.5 after:text-red-500"
                                                        htmlFor="grid-phone"
                                                    >
                                                        Phone
                                                    </label>
                                                    <input
                                                        className={`appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 ${
                                                            errors.phone && touched.phone
                                                                ? 'border-red-500'
                                                                : 'border-gray-200'
                                                        }`}
                                                        id="grid-phone"
                                                        type="number"
                                                        name="phone"
                                                        value={values.phone}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                    {errors.phone && touched.phone && (
                                                        <p className="text-red-500 text-xs italic">
                                                            {errors.phone}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="flex flex-wrap -mx-3 mb-6">
                                                <div className="w-full px-3">
                                                    <label
                                                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 after:content-['*'] after:ml-0.5 after:text-red-500"
                                                        htmlFor="grid-message"
                                                    >
                                                        Message
                                                    </label>
                                                    <textarea
                                                        className={`appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 ${
                                                            errors.message && touched.message
                                                                ? 'border-red-500'
                                                                : 'border-gray-200'
                                                        }`}
                                                        name="message"
                                                        rows={4}
                                                        value={values.message}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    ></textarea>
                                                    {errors.message && touched.message && (
                                                        <p className="text-red-500 text-xs italic">
                                                            {errors.message}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="flex flex-wrap mb-6 justify-center md:justify-start">
                                                <button
                                                    className={
                                                        !(dirty && isValid)
                                                            ? 'px-8 py-3 bg-sct-primary/25 rounded-md text-white cursor-not-allowed'
                                                            : ' px-8 py-3 bg-sct-primary rounded-md text-white cursor-pointer'
                                                    }
                                                    type="submit"
                                                    disabled={!(dirty && isValid)}
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </form>
                                    );
                                }}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactUs;
