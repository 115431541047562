import React from "react";
import Banner from "../../components/UI/Banner";
import ApplicationServicesHome from "../../components/UI/ApplicationServicesHome";

const ApplicationServices = () => {
  return (
    <div className="scroll-smooth">
      <Banner
        imgUrl={require("../../assets/images/clients/appservices-banner.png")}
        heading="Application Services"
      />
      <div>
        <ApplicationServicesHome />
      </div>
    </div>
  );
};

export default ApplicationServices;
