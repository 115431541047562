import React from 'react';

const BannerWButtons = (props) => {
  return (
    <div className="h-72 md:h-60 w-full relative flex justify-center md:justify-start items-center bg-sct-primary">
      <img src={props.imageUrl} className="h-72 md:h-60 w-full object-cover" />
      <div className="absolute bg-black/75 rounded-md md:left-10 mx-4 text-center md:text-left md:mx-4 mb-8 md:mb-0">
        <div className="p-6 ">
          <h1 className="text-2xl text-sct-white text-center">
            {props.jobTitle}
          </h1>
          <h3 className="text-xl text-sct-white mt-2 text-center">
            {props.jobLocation} | {props.jobType}
          </h3>
        </div>
      </div>
      <div className="absolute bottom-6 md:bottom-auto md:right-10">
        <button
          className="px-8 py-3 bg-sct-primary text-white rounded-md transition-all hover:bg-sct-secondary hover:text-black"
          onClick={props.onClick}
        >
          Apply Now
        </button>
      </div>
    </div>
  );
};

export default BannerWButtons;
