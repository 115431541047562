import React from 'react';

const AboutCompany = (props) => {
  const companyData = props.companyData;

  return (
    <div className="relative mt-16">
      <div className="container">
        {companyData.aboutCompany.map((data, index) => {
          return <p key={index}>{data}</p>;
        })}
        <h3 className="font-bold mt-2">Key Products:</h3>
        <ul className="list-disc ml-10 mt-4">
          {companyData.products.map((product, index) => {
            return <li key={index}>{product}</li>;
          })}
        </ul>
        <div className="mt-2">
          {companyData.productDescription.map((data, index) => {
            return <p key={index}>{data}</p>;
          })}
        </div>
      </div>
    </div>
  );
};

export default AboutCompany;
