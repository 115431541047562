import React, { useEffect, useState } from 'react';
import Banner from '../../components/UI/Banner';
import { FaSearch } from 'react-icons/fa';
import CareerCard from '../../components/UI/CareerCard';
import { useSelector, useDispatch } from 'react-redux';
import * as jobsActions from '../../store/actions/jobs';

const Careers = () => {
  const [searchText, setSearchText] = useState('');
  const [careersData, setCareersData] = useState([]);
  const storeJobs = useSelector((state) => state.jobs.jobs);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(jobsActions.fetchJobs()).then(() => {});
    console.log(storeJobs);
    setCareersData(storeJobs);
  }, []);
  const filterData = (event) => {
    setSearchText(event.target.value);
  };
  return (
    <div className="scroll-smooth">
      <Banner
        imgUrl={require('../../assets/images/clients/career.jpg')}
        heading="Careers"
      />
      <div className="bg-sct-white py-8">
        <div className="container">
          <h3 className="text-2xl font-bold mb-2">Search Positions</h3>
          <div className="pt-2 relative mx-auto text-gray-600">
            <button type="submit" className="absolute left-0 top-0 mt-5 ml-4">
              <FaSearch />
            </button>
            <input
              className="border-2 border-gray-300 bg-white h-10 px-5 pl-16 rounded-lg text-sm focus:outline-none w-full"
              type="search"
              name="search"
              placeholder="Search"
              onChange={filterData}
            />
          </div>
        </div>
      </div>
      <div className=" py-8">
        <div className="container">
          <h3 className="text-2xl font-bold mb-2">Open Positions</h3>
          {storeJobs
            .filter((val) => {
              if (searchText === '') {
                return val;
              } else if (
                val.data.title.toLowerCase().includes(searchText.toLowerCase())
              ) {
                return val;
              }
            })
            .map((data, key) => {
              return (
                <CareerCard
                  title={data.data.title}
                  description={data.data.description}
                  jobType={data.data.jobType}
                  location={data.data.location}
                  jobId={data.id}
                  key={key}
                  data={data.data}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Careers;
