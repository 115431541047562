import React from 'react';

const DataAnalyticsHeroSection = () => {
  return (
    <section className="relative my-16">
      <div className="container flex flex-col-reverse lg:flex-row items-center gap-12">
        {/* Content */}
        <div className="flex flex-1 flex-col items-center lg:items-start">
          <h2 className="text-sct-primary text-xl md:text-2xl lg:text-3xl text-center lg:text-left mb-6 font-semibold">
            Data Analytics - Consulting
          </h2>

          <p className="text-sct-grey text-center lg:text-left mb-6">
            Enterprises now more than ever have to build business resilience and ensure continuity in
            the face of the situation. With renewed focus, enterprise need to re-imagine the way they
            work to stabilize and take control of their business. Withstand impact, forecast, respond
            rapidly, adapt and recover better for the future. Data and insights can help build this
            resilience to help accelerate innovation, increase productivity, drive growth, reduce costs,
            mitigate risk and optimize asset utilization. Data and insights are strategic assets that
            helps the business learn about evolving opportunities, hidden threats, changing customer
            expectations and the competitive landscape, in context and in time to respond meaningfully.
            This, in turn, helps to automate the supply chain, drive continuous innovation, and create
            micro-moments based customer experience. Artificial Intelligence powers the core of this
            data-driven enterprise and creates signals that then act on the business to bring transformational
            value. And when this is enabled in a Do-it-Yourself culture, everyone, at any given time, is able
            to move quickly, in the right direction to defend, differentiate and even reimagine the business.
          </p>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            We provide cutting edge data analytics solutions to bridge the gap between
            research and real world applications in various streams.
            <ol className="list-disc ml-4 text-sct-grey">
              <br />
              <li className="ml-3 mb-3">
                Building intelligence systems that interpret data cognitively to discover new signals and connect the unconnected
              </li>
              <li className="ml-3 mb-3">
                Modernizing the core to prepare for the digital transformation
              </li>
              <li className="ml-3 mb-3">
                Data Science - To analyze, interpret and provide insights into
                your data.
              </li>
              <li className="ml-3 mb-3">
                Leveraging Artificial Intelligence to nurture a learnable and adaptable enterprise that evolves at digital speed
              </li>
            </ol>
          </p>
        </div>
        {/* Image */}
        <div className="flex justify-center flex-1 z-10">
          <img
            className="w-5/6 h-5/6 sm:w-3/4 sm:h-3/4 md:w-full md:h-full"
            src={require('../../../assets/images/clients/Data analysis-amico.png')}
          />
        </div>
      </div>
      <div className="container flex flex-col-reverse lg:flex-row-reverse items-center gap-12">
        {/* Content */}
        <div className="flex flex-1 flex-col items-center lg:items-start">
          <h2 className="text-sct-primary text-xl md:text-2xl lg:text-3xl text-center lg:text-left mb-6 font-semibold">
            Data Modernization
          </h2>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            Globally, customers are looking to transform to an analytics-driven enterprise and thereby be able to monetize
            their data for customer intimacy, operational efficiency and new revenue models. This is blocked by today's ageing and complex
            data landscapes, and its inability to discover & deliver information rapidly. Therefore,
            there is a shift in Modernization focus from technology adoption & cost optimization to aligning tightly to key business imperatives.
          </p>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            The conversion from a ‘Legacy Enterprise’ to a ‘Real-time Enterprise’ is enabled with the below 3 levers:
          </p>
          <p className="text-sct-grey text-center lg:text-left mb-6">
           <b>Optimize:</b> Multiple data marts, enterprise data warehouses, and reporting tools exist in enterprises today.
            This makes the ecosystem complex, costly and difficult to discover data. Enterprise data warehouses are peaking
            due to the increase in data and processing complexity. With the Data Optimization offerings, Infosys helps Enterprises
            to consolidate, reduce complexity, and stay on current versions in the data ecosystem.
          </p>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            <b>Transform:</b> With the Data Transformation offerings, we help enterprises create Data Lakes and Data Hubs,
            and enable and fast-track their cloud adoption. These solutions significantly reduce end-to-end data processing time,
            address performance issues with visualization, leading to reduced time-to-insights and ETL complexities.
          </p>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            <b>Digitize:</b> From our experience, we have seen enterprises spend 70-80% of their time on data acquisition and
            blending in their analytics projects. With our digitization offerings, comprising of new-age solutions like Infosys Nia,
            we provide data scientists with an easy-to-use environment. With drag drop-capability and advanced features like text analytics,
            enterprises can significantly reduce the time spent on data acquisition,
            thus enabling enterprises with self-service-based insights and intuitive visualizations for enhanced user experience.
          </p>
        </div>
        {/* Image */}
        <div className="flex justify-center flex-1 z-10">
          <img
              className="w-5/6 h-5/6 sm:w-3/4 sm:h-3/4 md:w-full md:h-full"
              src={require('../../../assets/images/clients/DataModern.png')}
          />
        </div>
      </div>
    </section>
  );
};

export default DataAnalyticsHeroSection;
