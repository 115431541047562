import React from "react";
import Marquee from 'react-fast-marquee';
import img1 from '../../../assets/images/clients/accenture.png';
import img2 from '../../../assets/images/clients/beaconhill.png';
import img3 from '../../../assets/images/clients/disys.png';
import img4 from '../../../assets/images/clients/ATT-Logo.png';
import img5 from '../../../assets/images/clients/nttdata.png';
import img6 from '../../../assets/images/clients/pyramid.png';
import img7 from '../../../assets/images/clients/santander.png';
import img8 from '../../../assets/images/clients/kroger.png';
import img9 from '../../../assets/images/clients/dexcom-logo.png';
import img10 from '../../../assets/images/clients/lt-logo.png';
import img11 from '../../../assets/images/clients/verizon-logo.png';
import img12 from '../../../assets/images/clients/infovision.png';
import img13 from '../../../assets/images/clients/wells-fargo-logo.png';
import img14 from '../../../assets/images/clients/republic_services_logo.png';
import img15 from '../../../assets/images/clients/kforce.png';
import img16 from '../../../assets/images/clients/teco.png';
import img17 from '../../../assets/images/clients/resilient-logo.png';
import img18 from '../../../assets/images/clients/philadelphia.png';
import img19 from '../../../assets/images/clients/michigan-logo.png';
import img20 from '../../../assets/images/clients/odin.png';
import img21 from '../../../assets/images/clients/z.png';
import img22 from '../../../assets/images/clients/apple-logo.png';
import img23 from '../../../assets/images/clients/tesla.png';
import img24 from '../../../assets/images/clients/blue.png';
import img25 from '../../../assets/images/clients/transdev.png';
import img26 from '../../../assets/images/clients/rivian.png';

const ClientSection = () => {
    return (
        <section className="bg-white py-20">
            <div className="sm:w-3/4 mx-auto px-2 ">
                <h1 className="text-3xl text-center text-sct-primary font-semibold">
                    Our Valuable Customers
                </h1>
            </div>

           <div className="text-center text-sct-grey mt-16">
                <Marquee direction="right" speed={100} delay={1} loop={0} pauseOnHover={true} gradient={false}>
                    <div className="image_wrapper">
                        <img src={img1} alt=""/>
                    </div>
                    <div className="image_wrapper">
                        <img src={img2} alt=""/>
                    </div>
                    <div className="image_wrapper">
                        <img src={img3} alt=""/>
                    </div>
                    <div className="image_wrapper">
                        <img src={img4} alt=""/>
                    </div>
                    <div className="image_wrapper">
                        <img src={img5} alt=""/>
                    </div>
                    <div className="image_wrapper">
                        <img src={img6} alt=""/>
                    </div>
                    <div className="image_wrapper">
                        <img src={img7} alt=""/>
                    </div>
                    <div className="image_wrapper">
                        <img src={img8} alt=""/>
                    </div>
                    <div className="image_wrapper">
                        <img src={img9} alt=""/>
                    </div>
                    <div className="image_wrapper">
                        <img src={img10} alt=""/>
                    </div>
                    <div className="image_wrapper">
                        <img src={img11} alt=""/>
                    </div>
                    <div className="image_wrapper">
                        <img src={img12} alt=""/>
                    </div>
                    <div className="image_wrapper">
                        <img src={img13} alt=""/>
                    </div>
                    <div className="image_wrapper">
                        <img src={img14} alt=""/>
                    </div>
                    <div className="image_wrapper">
                        <img src={img15} alt=""/>
                    </div>
                    <div className="image_wrapper">
                        <img src={img16} alt=""/>
                    </div>
                    <div className="image_wrapper">
                        <img src={img17} alt=""/>
                    </div>
                    <div className="image_wrapper">
                        <img src={img18} alt=""/>
                    </div>
                    <div className="image_wrapper">
                        <img src={img19} alt=""/>
                    </div>
                    <div className="image_wrapper">
                        <img src={img20} alt=""/>
                    </div>
                    <div className="image_wrapper">
                        <img src={img21} alt=""/>
                    </div>
                    <div className="image_wrapper">
                        <img src={img22} alt=""/>
                    </div>
                    <div className="image_wrapper">
                        <img src={img23} alt=""/>
                    </div>
                    <div className="image_wrapper">
                        <img src={img24} alt=""/>
                    </div>
                    <div className="image_wrapper">
                        <img src={img25} alt=""/>
                    </div>
                    <div className="image_wrapper">
                        <img src={img26} alt=""/>
                    </div>
                </Marquee>
            </div>
        </section>
    );
};

export default ClientSection;
