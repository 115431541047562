import React from "react";
import Shop from "../../../assets/images/clients/shop.svg";
import Invest from "../../../assets/images/clients/Investment.svg";
import Hrms from "../../../assets/images/clients/team-bro.svg";
import IScrum from "../../../assets/images/clients/iscrum1.svg";
import DatrixAI from "../../../assets/images/clients/DatrixAI1.svg";

import { useNavigate} from "react-router-dom";

const OurProducts = () => {
    const navigate = useNavigate();
    return (
        <section className="bg-white py-10">
            <div className="container">
                <h1 className="text-3xl text-center text-sct-primary font-semibold">
                    Our Products
                </h1>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10 my-16">
                    <div className="rounded-lg overflow-hidden">
                        <div className="bg-orange-200 py-4 px-2 cursor-pointer object-contain transition-all hover:scale-110" onClick={() => navigate("products/i-scrumai")}>
                            <img src={IScrum} />
                        </div>

                        <div>
                            <p className="my-4 text-xl text-gray-600"> AI Scrum Master</p>
                            <h3 className="mb-6 text-3xl font-bold">iScrum AI</h3>
                            {/*<p className="text-2xl font-extralight text-gray-500">*/}
                            {/*    Become a more efficient BizTalk Server administrator with*/}
                            {/*    BizTalk360.*/}
                            {/*</p>*/}
                        </div>
                    </div>
                    <div className="rounded-lg overflow-hidden">
                        <div className="bg-orange-200 py-4 px-2 cursor-pointer object-contain transition-all hover:scale-110" onClick={() => navigate("products/datrix")}>
                            <img src={DatrixAI} />

                        </div>

                        <div>
                            <p className="my-4 text-xl text-gray-600">AI Engine</p>
                            <h3 className="mb-8 text-3xl font-bold">Datrix AI</h3>
                        </div>
                    </div>
                    <div className="rounded-lg overflow-hidden">
                        <div className="bg-orange-200 py-4 px-2 cursor-pointer object-contain transition-all hover:scale-110" onClick={() => navigate("products/shopzilla")}>
                            <img src={Shop} />
                        </div>

                        <div>
                            <p className="my-4 text-xl text-gray-600">E-Commerce Suite</p>
                            <h3 className="mb-6 text-3xl font-bold">Shopzilla</h3>
                        </div>
                    </div>
                    <div className="rounded-lg overflow-hidden">
                        <div className="bg-orange-200 py-4 px-2 cursor-pointer object-contain transition-all hover:scale-110" onClick={() => navigate("products/investify")}>
                            <img src={Invest} />
                        </div>

                        <div>
                            <p className="my-4 text-xl text-gray-600">Investment Platform</p>
                            <h3 className="mb-6 text-3xl font-bold">Investify</h3>
                            {/*</p>*/}
                        </div>
                    </div>
                    {/*<div className="rounded-lg overflow-hidden">
                        <div className="bg-orange-200 py-4 px-2">
                            <img src={Hrms} />
                        </div>

                        <div>
                            <p className="my-4 text-xl text-gray-600">HRMS System</p>
                            <h3 className="mb-6 text-3xl font-bold">E-Serve</h3>
                        </div>
                    </div>*/}
                </div>
            </div>
        </section>
    );
};

export default OurProducts;
