import React, { useEffect, useRef, useState } from 'react';
import AboutCompany from '../../components/UI/AboutCompany';
import BannerWButtons from '../../components/UI/BannerWButtons';
import { useNavigate, useParams } from 'react-router-dom';
import AboutJob from '../../components/UI/AboutJob';
import { Formik } from 'formik';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { storage } from '../../firebase';
import { db } from '../../firebase';
import { collection, addDoc, Timestamp } from 'firebase/firestore';
import LoadingSpinner from '../../components/shared/LoadingSpinner';
import ModelBox from '../../components/shared/ModelBox';
import { useDispatch, useSelector } from 'react-redux';
import * as jobsActions from '../../store/actions/jobs';

const CareerDetails = () => {
  const { id } = useParams();
  const formRef = useRef(null);
  const navigate = useNavigate();
  const [showForm, setShowForm] = useState(false);
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [hidden, setHidden] = useState(true);
  const [notLoading, setNotLoading] = useState(true);
  const [careersData, setCareersData] = useState([]);
  const storeJobs = useSelector((state) => state.jobs.jobs);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(jobsActions.fetchJobs()).then(() => {});
  }, []);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const executeScroll = async () => {
    await setShowForm(true);
    formRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'nearest',
    });
  };

  const companyData = {
    aboutCompany: [
      'Source Code Technologies LLC Services is a global business and IT services provider specializing in Artificial Intelligence & Machine learning, digital, cloud and automation across a comprehensive portfolio of consulting, applications, infrastructure and business process services..',
      'We strives to hire exceptional, innovative and passionate individuals who want to grow with us. If you want to be part of an inclusive, adaptable, and forward-thinking organization, apply now',
      'We are currently seeking a Digital Engineering Lead Advisor to join our team in Irvine, California (US-CA), United States (US).',
    ],
    products: ['iScrum-AI', 'Datrix-AI', 'Investify', 'Shopzilla'],
    productDescription: [
      'We wish to be known for our values of integrity, teamwork and excellence.',
      'We are constantly on the lookout for smart people who are passionate about building great products, designing great experiences, building scalable platforms, and making customers happy. If you’re looking to make an impact, Source Code Techno is the place for you. If this describes you, feel free to have a look at our openings in our career page and apply to be a part of the passionate journey!',
    ],
  };
  const [data, setData] = useState({
    name: '',
    file: '',
  });
  const handleInputState = (name, value) => {
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const jobData = storeJobs.filter((data) => data.id === id)[0];

  const initialValues = {
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    mobile: '',
    resume: '',
  };

  const validate = (values) => {
    let errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.firstName) {
      errors.firstName = 'This field is required';
    }
    if (!values.lastName) {
      errors.lastName = 'This field is required';
    }

    if (!values.email) {
      errors.email = 'Email is required';
    } else if (!regex.test(values.email)) {
      errors.email = 'Invalid Email';
    }

    if (!values.mobile) {
      errors.mobile = 'This field is required';
    }

    // if (!values.resume) {
    //   errors.resume = 'This field is required';
    // }
    return errors;
  };

  const submitForm = async (values) => {
    if (!file) {
      alert('Please choose your resume');
      return;
    }
    setNotLoading(false);
    const storageRef = ref(storage, `files/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(prog);
      },
      (error) => {
        console.log(error);
        setNotLoading(true);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
          try {
            await addDoc(collection(db, 'resume'), {
              firstName: values.firstName,
              middleName: values.middleName,
              lastName: values.lastName,
              email: values.email,
              mobile: values.mobile,
              resume: url,
              jobId: id,
              jobTitle: jobData.data.title,
              created: Timestamp.now(),
            });
          } catch (err) {
            console.log(err);
          } finally {
            setNotLoading(true);
            setHidden(false);
          }
        });
      }
    );

    // try {
    //   console.log(values);
    //   const url = process.env.REACT_APP_API_URL + "/resumes";

    //   const { data: res } = await axios.post(url, values);
    //   console.log(res);
    // } catch (error) {
    //   console.log(error);
    // }
  };

  const onOkay = () => {
    setHidden(true);
    navigate('/');
  };

  return (
    <>
      <BannerWButtons
        jobTitle={jobData.data.title}
        jobLocation={jobData.data.location}
        jobType={jobData.data.jobType}
        imageUrl={require('../../assets/images/clients/jobdetail.jpg')}
        onClick={executeScroll}
      />
      <LoadingSpinner hidden={notLoading} />
      <ModelBox
        hidden={hidden}
        title="Your application submitted!"
        subTitle="Thanks for your application. Our hr team will contact you soon.."
        onOkay={onOkay}
      />
      <AboutCompany companyData={companyData} />
      <AboutJob jobData={jobData.data} />

      <div
        className={`container pb-8 ${showForm ? '' : 'hidden'}`}
        ref={formRef}
      >
        <h1 className="text-3xl pb-6 tracking-tight font-bold text-sct-primary">
          Submit Your Application
        </h1>

        <Formik
          initialValues={initialValues}
          validate={validate}
          onSubmit={submitForm}
        >
          {(formik) => {
            const {
              values,
              handleChange,
              handleSubmit,
              errors,
              touched,
              handleBlur,
              isValid,
              dirty,
            } = formik;
            return (
              <form onSubmit={handleSubmit}>
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 after:content-['*'] after:ml-0.5 after:text-red-500"
                      htmlFor="grid-first-name"
                    >
                      First Name
                    </label>
                    <input
                      className={`appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 ${
                        errors.firstName && touched.firstName
                          ? 'border-red-500'
                          : 'border-gray-200'
                      }`}
                      id="grid-first-name"
                      type="text"
                      name="firstName"
                      value={values.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.firstName && touched.firstName && (
                      <p className="text-red-500 text-xs italic">
                        {errors.firstName}
                      </p>
                    )}
                  </div>
                  <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 "
                      htmlFor="grid-middle-name"
                    >
                      Middle Name
                    </label>
                    <input
                      className={`appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 ${
                        errors.middleName && touched.middleName
                          ? 'border-red-500'
                          : 'border-gray-200'
                      }`}
                      id="grid-middle-name"
                      type="text"
                      name="middleName"
                      value={values.middleName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.middleName && touched.middleName && (
                      <p className="text-red-500 text-xs italic">
                        {errors.middleName}
                      </p>
                    )}
                  </div>
                  <div className="w-full md:w-1/3 px-3">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 after:content-['*'] after:ml-0.5 after:text-red-500"
                      htmlFor="grid-last-name"
                    >
                      Last Name
                    </label>
                    <input
                      className={`appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 ${
                        errors.lastName && touched.lastName
                          ? 'border-red-500'
                          : 'border-gray-200'
                      }`}
                      id="grid-first-name"
                      type="text"
                      name="lastName"
                      value={values.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.lastName && touched.lastName && (
                      <p className="text-red-500 text-xs italic">
                        {errors.lastName}
                      </p>
                    )}
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full px-3">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 after:content-['*'] after:ml-0.5 after:text-red-500"
                      htmlFor="grid-email"
                    >
                      Email
                    </label>
                    <input
                      className={`appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 ${
                        errors.email && touched.email
                          ? 'border-red-500'
                          : 'border-gray-200'
                      }`}
                      id="grid-email"
                      type="text"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.email && touched.email && (
                      <p className="text-red-500 text-xs italic">
                        {errors.email}
                      </p>
                    )}
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full px-3">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 after:content-['*'] after:ml-0.5 after:text-red-500"
                      htmlFor="grid-mobile"
                    >
                      Mobile
                    </label>
                    <input
                      className={`appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 ${
                        errors.mobile && touched.mobile
                          ? 'border-red-500'
                          : 'border-gray-200'
                      }`}
                      id="grid-mobile"
                      type="text"
                      name="mobile"
                      value={values.mobile}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.mobile && touched.mobile && (
                      <p className="text-red-500 text-xs italic">
                        {errors.mobile}
                      </p>
                    )}
                  </div>
                </div>

                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full px-3">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 after:content-['*'] after:ml-0.5 after:text-red-500"
                      htmlFor="input-resume"
                    >
                      Resume
                    </label>
                    <input
                      className={`appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 ${
                        errors.resume && touched.resume
                          ? 'border-red-500'
                          : 'border-gray-200'
                      }`}
                      type="file"
                      name="resume"
                      onChange={handleFileChange}
                    />
                  </div>
                </div>

                <div className="flex flex-wrap mb-6 justify-center md:justify-start">
                  <button
                    className={
                      !(dirty && isValid)
                        ? 'px-8 py-3 bg-sct-primary/25 rounded-md text-white cursor-not-allowed'
                        : ' px-8 py-3 bg-sct-primary rounded-md text-white cursor-pointer'
                    }
                    onClick={submitForm}
                    type="submit"
                    disabled={!(dirty && isValid)}
                  >
                    Submit
                  </button>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default CareerDetails;
