import React from "react";
import CookieConsent from "react-cookie-consent";
import { useNavigate } from "react-router-dom";

const CookieConsentPopup = () => {
  const navigate = useNavigate();
  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept Cookies"
      cookieName="myAwesomeCookieName2"
      style={{ background: "#2B373B" }}
      buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
      expires={150}
    >
      We use cookies to personalise content and ads, to provide social media
      features and to analyse our traffic. We also disclose information about
      your use of our site with our social media, advertising and analytics
      partners. <br />
      Additional details are available in our &nbsp;
      <a
        className="cursor-pointer"
        onClick={() => navigate("/cookie-declaration")}
      >
        Cookies declaration
      </a>
    </CookieConsent>
  );
};

export default CookieConsentPopup;
