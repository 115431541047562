import { collection, query, orderBy, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase';

export const SET_JOBS = 'SET_JOBS';

export const fetchJobs = () => {
  return async (dispatch) => {
    const q = query(collection(db, 'jobs'), orderBy('created', 'desc'));
    onSnapshot(q, (querySnapshot) => {
      const jobs = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));
      dispatch({ type: SET_JOBS, jobs: jobs });
    });
  };
};
