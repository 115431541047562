import React from 'react';
import {FaAws, FaJava} from "react-icons/fa";
import {
    SiAndroidstudio,
    SiAngular,
    SiDocker,
    SiDotnet,
    SiJavascript,
    SiKubernetes,
    SiMicrosoftazure, SiNodedotjs, SiPython,
    SiReact
} from "react-icons/si";

const TeamExpertise = () => {
    return (
        <section className="bg-sct-white py-20">
            <div className="sm:w-3/4 mx-auto px-2">
                <h1 className="text-3xl text-center text-sct-primary font-semibold">
                    Our Team Expertise
                </h1>
            </div>

            {/* Cards */}
            <div className="container">
                <p className="text-center text-sct-grey mt-4">
                    We provide professional team which are adapt with all the latest technologies.
                    Our team are here to help your business addressing key technology challenges,
                    enhance productivity and reduce costs.
                </p>
                <div className="container grid gap-4 grid-cols-2 md:grid-cols-4 lg:grid-cols-6 max-w-screen-lg mt-16">
                    <div className="flex flex-col items-center justify-center">
                        <div className="bg-red-500 p-6 rounded-full object-contain transition-all hover:scale-125 cursor-pointer">
                            <FaJava size={40} className={"text-amber-50"} />
                        </div>
                        <p className="text-sct-grey font-semibold mt-2">Java</p>
                    </div>
                    <div className="flex flex-col items-center justify-center">
                        <div className="bg-blue-500 p-6 rounded-full object-contain transition-all hover:scale-125 cursor-pointer">
                            <SiDotnet size={40} className="text-amber-50" />
                        </div>
                        <p className="text-sct-grey font-semibold mt-2">.Net</p>
                    </div>
                    <div className="flex flex-col items-center justify-center">
                        <div className="bg-yellow-500 p-6 rounded-full object-contain transition-all hover:scale-125 cursor-pointer">
                            <SiJavascript size={40} className="text-neutral-900-200" />
                        </div>
                        <p className="text-sct-grey font-semibold mt-2">Javascript</p>
                    </div>
                    <div className="flex flex-col items-center justify-center">
                        <div className="bg-orange-500 p-6 rounded-full object-contain transition-all hover:scale-125 cursor-pointer">
                            <SiReact size={40} className="text-black-200" />
                        </div>
                        <p className="text-sct-grey font-semibold mt-2">React</p>
                    </div>
                    <div className="flex flex-col items-center justify-center">
                        <div className="bg-green-100 p-6 rounded-full object-contain transition-all hover:scale-125 cursor-pointer">
                            <SiAngular size={40} className="text-red-500" />
                        </div>
                        <p className="text-sct-grey font-semibold mt-2">Angular</p>
                    </div>
                    <div className="flex flex-col items-center justify-center">
                        <div className="bg-cyan-100 p-6 rounded-full object-contain transition-all hover:scale-125 cursor-pointer">
                            <SiAndroidstudio size={40} className="text-green-500" />
                        </div>
                        <p className="text-sct-grey font-semibold mt-2">Android</p>
                    </div>
                    <div className="flex flex-col items-center justify-center">
                        <div className="bg-yellow-200 p-6 rounded-full object-contain transition-all hover:scale-125 cursor-pointer">
                            <SiPython size={40} className="text-blue-500" />
                        </div>
                        <p className="text-sct-grey font-semibold mt-2">Python</p>
                    </div>
                    <div className="flex flex-col items-center justify-center">
                        <div className="bg-green-500 p-6 rounded-full object-contain transition-all hover:scale-125 cursor-pointer">
                            <SiNodedotjs size={40} className="text-black-500" />
                        </div>
                        <p className="text-sct-grey font-semibold mt-2">Node JS</p>
                    </div>
                    <div className="flex flex-col items-center justify-center">
                        <div className="bg-orange-300 p-6 rounded-full object-contain transition-all hover:scale-125 cursor-pointer">
                            <FaAws size={40} className="text-black-400" />
                        </div>
                        <p className="text-sct-grey font-semibold mt-2">AWS</p>
                    </div>
                    <div className="flex flex-col items-center justify-center">
                        <div className="bg-blue-700 p-6 rounded-full object-contain transition-all hover:scale-125 cursor-pointer">
                            <SiMicrosoftazure size={40} className="text-blue-400" />
                        </div>
                        <p className="text-sct-grey font-semibold mt-2">Azure</p>
                    </div>
                    <div className="flex flex-col items-center justify-center">
                        <div className="bg-blue-300 p-6 rounded-full object-contain transition-all hover:scale-125 cursor-pointer">
                            <SiDocker size={40} className="text-blue-500" />
                        </div>
                        <p className="text-sct-grey font-semibold mt-2">Docker</p>
                    </div>
                    <div className="flex flex-col items-center justify-center">
                        <div className="bg-gray-300 p-6 rounded-full object-contain transition-all hover:scale-125 cursor-pointer">
                            <SiKubernetes size={40} className="text-blue-700" />
                        </div>
                        <p className="text-sct-grey font-semibold mt-2">Kubernetes</p>
                    </div>
                </div>
            </div>

        </section>
    );
};

export default TeamExpertise;