import React from 'react';
import Banner from '../../components/UI/Banner';
import ScrumHeroSection from '../../components/UI/ScrumHeroSection';
import ScrumChallengesSection from '../../components/UI/ScrumChallengesSection';
import ScrumSolution from '../../components/UI/ScrumSolution';


const iScrum = () => {
  return (
    <div className="scroll-smooth">
      <Banner
        imgUrl={require('../../assets/images/scrum_new.png')}
        heading="iScrum AI"
      />
      <div>
        <ScrumHeroSection />
        <ScrumChallengesSection />
        <ScrumSolution />
      </div>
    </div>
  );
};

export default iScrum;
