import { SET_JOBS } from '../actions/jobs';

const initialState = {
  jobs: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_JOBS:
      return {
        jobs: action.jobs,
      };
  }
  return state;
};
