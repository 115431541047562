import React from 'react';

const TechnologyConsultingHome = () => {
  return (
    <section className="relative my-16">
      <div className="container flex flex-col-reverse lg:flex-row items-center gap-12">
        {/* Content */}
        <div className="flex flex-1 flex-col items-center lg:items-start">
          <h2 className="text-sct-primary text-xl md:text-2xl lg:text-3xl text-center lg:text-left mb-6 font-semibold">
            Resilient modern architecture
          </h2>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            Make the most of modern architecture technologies and techniques to improve business continuity and reduce operational risk.
          </p>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            Resiliency is a characteristic to be built within system such that it encompasses the ability to recover quickly from the failures.
            Lack of resilience reduces the system availability. In today’s modern architecture systems are built as Microservices,
            on cloud and various other technology options available that brings higher levels of agility, effective cost management
            and innovation to the business. This also mandates for building system with certain required characteristics so that systems
            are resilient to failures.
          </p>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            Choosing right platform, technology & design is one of the key fundamental requirement for making system resilient,
            fault tolerant and highly available. Choosing appropriate architecture, stack and approach for solving business problem
            is required for fully reaping the benefit and incorporating the characteristics.
          </p>
        </div>
        {/* Image */}
        <div className="flex justify-center flex-1 z-10">
          <img
            className="w-5/6 h-5/6 sm:w-3/4 sm:h-3/4 md:w-full md:h-full"
            src={require('../../../assets/images/clients/resilent.png')}
          />
        </div>
      </div>
      <div className="container flex flex-col-reverse lg:flex-row-reverse items-center gap-12">
        {/* Content */}
        <div className="flex flex-1 flex-col items-center lg:items-start">
          <h2 className="text-sct-primary text-xl md:text-2xl lg:text-3xl text-center lg:text-left mb-6 font-semibold">
            Cloud acceleration
          </h2>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            Digital transformation has spread throughout nearly every industry as the world embraces modern technology to streamline processes,
            reduce costs, and provide advanced capabilities. A significant element of digital transformation for many businesses is
            migrating internal and external services to the cloud.
          </p>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            Cloud acceleration is a multi-faceted operation and will vary based on your business needs. For example, a cloud accelerator might create abstraction
            layers between your systems and the cloud, embrace entirely cloud-managed services, or leverage infrastructure-as-a-service (IaaS) platforms.
          </p>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            The end result of cloud acceleration is enhanced internal and external systems’ performance, reliability, and deliverability.
          </p>
        </div>
        {/* Image */}
        <div className="flex justify-center flex-1 z-10">
          <img
            className="w-5/6 h-5/6 sm:w-3/4 sm:h-3/4 md:w-full md:h-full"
            src={require('../../../assets/images/clients/cloudacceleration.png')}
          />
        </div>
      </div>

      <div className="container flex flex-col-reverse lg:flex-row items-center gap-12">
        {/* Content */}
        <div className="flex flex-1 flex-col items-center lg:items-start">
          <h2 className="text-sct-primary text-xl md:text-2xl lg:text-3xl text-center lg:text-left mb-6 font-semibold">
            Technology Stack Refresh
          </h2>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            A tech stack is the combination of technologies a company uses to build and run an application or project.
            Sometimes called a “solutions stack,” a tech stack typically consists of programming languages, frameworks,
            a database, front-end tools, back-end tools, and applications connected via APIs.
          </p>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            Investing in the various elements of your tech stack is a crucial step to finding success as a software company,
            since your tech stack gives your product team the tools it needs to build and maintain your product, and to make
            sure it continues to meet customer needs.
          </p>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            As a premier IT services company, we believe in maintaining a
            positive mindset and creating partnerships with purpose. We strive
            for significant outcomes to better serve our clients. Contact us
            today for a consultation.
          </p>
        </div>
        {/* Image */}
        <div className="flex justify-center flex-1 z-10">
          <img
            className="w-5/6 h-5/6 sm:w-3/4 sm:h-3/4 md:w-full md:h-full"
            src={require('../../../assets/images/clients/techstack.png')}
          />
        </div>
      </div>

      <div className="container flex flex-col-reverse lg:flex-row-reverse items-center gap-12">
        {/* Content */}
        <div className="flex flex-1 flex-col items-center lg:items-start">
          <h2 className="text-sct-primary text-xl md:text-2xl lg:text-3xl text-center lg:text-left mb-6 font-semibold">
            Data-driven enterprise
          </h2>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            Rapidly accelerating technology advances, the recognized value of data, and increasing data literacy are changing
            what it means to be “data driven.”
          </p>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            When embracing a data-driven approach to EA, we can quickly, easily, and literally in seconds create many different visual
            representations of the same structured data. We also don’t need to redraw anything when the data changes,
            but changes in data — ‘at the source of truth’ as we like to call it — automatically trigger updates in all
            visualizations everywhere. This not only saves thousands of hours of time, but also removes the possibility
            of human error in the updating of visualizations.
          </p>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            We live in a data economy. Data powers most of today’s most successful digital enterprises, and is poised to disrupt many traditional
            industries that have not yet realized the transformative power of data within their business.
          </p>
        </div>
        {/* Image */}
        <div className="flex justify-center flex-1 z-10">
          <img
            className="w-5/6 h-5/6 sm:w-3/4 sm:h-3/4 md:w-full md:h-full"
            src={require('../../../assets/images/clients/datadriven.png')}
          />
        </div>
      </div>
    </section>
  );
};

export default TechnologyConsultingHome;
