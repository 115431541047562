import React from 'react';
import Banner from '../../components/UI/Banner';
import DatrixHeroSection from '../../components/UI/DatrixHeroSection';
import DatrixSimilitySection from '../../components/UI/DatrixSimility';
import DatrixMachineLearningSection from '../../components/UI/DatrixMachineLearning';

const Datrix = () => {
  return (
    <div className="scroll-smooth">
      <Banner
        imgUrl={require('../../assets/images/clients/datrix_banner.png')}
        heading="DaTriX AI"
      />
      <div>
        <DatrixHeroSection />
        <DatrixSimilitySection />
        <DatrixMachineLearningSection />
      </div>
    </div>
  );
};

export default Datrix;
