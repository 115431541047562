import React from "react";
import Banner from "../../components/UI/Banner";
import TechnologyInnovationHome from "../../components/UI/TechnologyInnovationHome";

const TechnologyInnovation = () => {
  return (
    <div className="scroll-smooth">
      <Banner
        imgUrl={require("../../assets/images/clients/blockchain-banner.png")}
        heading="Technology Innovation"
      />
      <div>
        <TechnologyInnovationHome />
      </div>
    </div>
  );
};

export default TechnologyInnovation;
