import React from 'react';

const ScrumChallengesSection = () => {
  return (
    <section className="relative my-16">
      <div className="container flex flex-col-reverse lg:flex-row-reverse items-center gap-12">
        {/* Content */}
        <div className="flex flex-1 flex-col items-center lg:items-start">
          <h2 className="text-sct-primary text-xl md:text-2xl lg:text-3xl text-center lg:text-left mb-6 font-semibold">
            Every Organizational Challenge Presents An Opportunity For Growth
          </h2>
          <p className="text-2xl font-bold mb-2">
            Some common challenges organizations face in meetings are:
          </p>
          <ol className="list-disc ml-4 text-sct-grey">
            <br />
            <li className="ml-3 mb-3">
              Meetings that run long or don’t start on time.
            </li>
            <li className="ml-3 mb-3">
              Core objectives were not achieved.
            </li>
            <li className="ml-3 mb-3">Key decision makers were not in attendance.</li>
            <li className="ml-3 mb-3">Off-topic discussions prevented some participants from speaking.</li>
            <li className="ml-3 mb-3">Next steps and follow-through expectations were not established.</li>
          </ol>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            When any of the above happens, the resulting consequences include wasted time, inefficient
            resource allocation, and lack of optimal productivity.
          </p>
          <p className="text-sct-grey text-center lg:text-left mb-6">
            Source Code Technologies can turn your organizational challenges into strengths, with a
            powerful solution that drives meeting productivity, facilitates seamless collaboration, and
            enables real-time progress toward key goals and objectives.
          </p>
        </div>
        {/* Image */}
        <div className="flex justify-center flex-1 z-10">
          <img
            src={require('../../../assets/images/clients/scrumchallenge_new.png')}
          />
        </div>
      </div>
    </section>
  );
};

export default ScrumChallengesSection;
