import React, { useState } from 'react';
import ScaleLoader from 'react-spinners/ScaleLoader';

const LoadingSpinner = (props) => {
  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState('#5E29EB');
  return (
    <div
      className={`min-w-screen h-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover ${
        props.hidden && 'hidden'
      }`}
      id="modal-id"
    >
      <div className="absolute bg-black/80 inset-0 z-0"></div>
      <div className="min-w-screen h-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover">
        <ScaleLoader color={color} loading={loading} />
      </div>
    </div>
  );
};

export default LoadingSpinner;
